import React, { Component, Fragment, useState } from "react";
import { Redirect, Route, Link } from 'react-router-dom';
import UserService from "../services/user.service";
import "../assets/css/crops.css";
import MaterialTable from "material-table";
import tableIcons from './icons';
import {TriggerAlert,} from './dryfunctions';
import AuthService from "../services/auth.service";


import {

    Row,
    Col,
    Form
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faHtml5, faHome } from "@fortawesome/free-solid-svg-icons";
//have used class component . Initially we are defining all the state variables which are used inside the component.

export default class FpoFI extends Component {
    constructor(props) {
        super(props)
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSeasonChange = this.handleSeasonChange.bind(this);
        
        this.state = {
            fpoFIcounts: [],
            loading: false,
            dateRanges: [],
            getProjectList:[],
            SelDate: "all",
            showloader:true,
            SelSeason: "all",
            monthRanges:[],
            SelOrg:"all",
            SelOrgId:''
          };
    }

  //   handleOrgChange = (e) => {
  //     this.setState(
  //       {
  //         SelOrg: e.target.value,
  //         fpoFIcounts: [],
  //         showloader:true
  //       }
  //       );
  //       this.fetchCropsCount( this.state.SelDate, this.state.SelSeason , e.target.value)
  // }

  handleOrgChange = (e) => {
    const selectedOrgName = e.target.value;
    
    // Find the selected organization by name
    const selectedOrg = this.state.getProjectList.find(org => org.name === selectedOrgName);
    
    // Update state with selected organization details
    this.setState({
      SelOrg: selectedOrgName, // Store the selected name
      SelOrgId: selectedOrg ? selectedOrg.id : null, // Store the selected id
      fpoFIcounts: [],
      showloader: true,
    });
  
    // Call fetchCropsCount with the selected organization's id
    this.fetchCropsCount(this.state.SelDate, this.state.SelSeason, selectedOrg ? selectedOrg.id : 'all');
  }
    handleDateChange = (e) => {
        this.setState(
          {
            SelDate: e.target.value,
            fpoFIcounts: [],
            showloader:true
          }
          );
          this.fetchCropsCount(e.target.value, this.state.SelSeason, this.state.SelOrgId?this.state.SelOrgId:'all')
    }
  
    handleSeasonChange = (e) => {
        this.setState(
          {
            SelSeason: e.target.value,
            fpoFIcounts: [],
            showloader:true
          }
        )
        this.fetchCropsCount(this.state.SelDate, e.target.value, this.state.SelOrgId?this.state.SelOrgId:'all')
    }

    // initialCall = ()=>{
    //   this.setState({
        
    //     showloader:true,
    //     fpoFIcounts: [],
    //   })
    //   this.fetchCropsCount('all', 'all', 'all')
    // }

    navigateToPage2= (pageName) => {
       
      this.props.history.push("/" + pageName + "");
    };

  //   fetchCropsCount = (range, month, org) => {
  //     // Set loader to true before making the API call
  //     this.setState({ showloader: true, fpoFIcounts: [] });
  
  //     // Flag to monitor if response is received
  //     let flag = false;
  
  //     UserService.getFpoFIsCount(range, month, org).then(
  //         (response) => {
  //             flag = true;  // Mark flag as true, indicating API responded
  //             if (response.status === 200) {
  //                 this.setState({
  //                     showloader: false,
  //                     fpoFIcounts: response.data.fpo_fi_data,
  //                 });
  //             } else {
  //                 this.setState({ showloader: false });
  //                 TriggerAlert("Error", response.data.message || "Something went wrong", "error");
  //             }
  //         },
  //         (error) => {
  //             flag = true;  // Mark flag as true, indicating API responded with error
  //             this.setState({
  //                 showloader: false,
  //                 content: error.response?.data?.message || error.message || error.toString(),
  //             });
  //             TriggerAlert("Error", error.response?.data?.message || "Server closed unexpectedly, Please try again", "error");
  //         }
  //     );
  
  //     // Set a timeout for response failure detection
  //     setTimeout(() => {
  //         if (!flag) {  // If flag is still false, it means the response is delayed
  //             this.setState({ showloader: false });
  //             TriggerAlert("Error", "Response Timed out, Please try again", "info");
  //             this.props.history.push('/fpohomeData');
  //         }
  //     }, 30000);  // Timeout set to 30 seconds
  // }
  
    fetchCropsCount = (range, month, org) => {
      this.setState({ showloader: true, fpoFIcounts: [] });
        var flag = false;
      
        UserService.getFpoFIsCount(range, month, org).then(
          
            (response) => {
                flag = true; 
                this.setState({
                  showloader:true,
                  fpoFIcounts:[]
                })
                if (response.data.success) {
                    this.setState({
                        showloader:false,
                        fpoFIcounts: response.data.fpo_fi_data,
                        // ranges: response.data.date_ranges_list

                    });
                }
                else {
                    this.setState({
                        showloader: false,
                    });
                    if (response){
                        TriggerAlert("Error",response.data.message,"error");
                      }
                      else {
                        TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
                      }
                }
            },
            (error) => {
                flag = true; 
                this.setState({
                    showloader: false,
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString(),
                });
                if (error.response){
                    TriggerAlert("Error",error.response.data.message,"error");
                  }
                  else {
                    TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
                  }
            },
            setTimeout(() => {
                if(flag==false){
                    this.setState({
                        showloader:false,
                    });
                TriggerAlert("Error","Response Timed out, Please try again","info");
                this.props.history.push('/fpohomeData')             
              }
            }, 30000)
        );
    }
    

      


    componentDidMount() {
        // if(!localStorage.getItem('user')){
        //     this.props.history.push('/')
        //     return
        //   }
        var flag = false;
          const user = AuthService.getCurrentUser();
          const fpoId = localStorage.getItem("fpoId")
          if(!user){
            this.props.history.push('/')
            return
          }
          if(!user.is_parent && !user.is_project_manager)
          this.props.history.push("/dashboard")
       
          this.fetchCropsCount('all', 'all', 'all')
        //   UserService.getFpoFIsCount('all', 'all', 'all').then(
          
        //     (response) => {
        //         flag = true; 
        //         this.setState({
        //           showloader:true,
        //           fpoFIcounts:[]
        //         })
        //         if (response.data.success) {
        //             this.setState({
        //                 showloader:false,
        //                 fpoFIcounts: response.data.fpo_fi_data,
        //                 // ranges: response.data.date_ranges_list

        //             });
        //         }
        //         else {
        //             this.setState({
        //                 showloader: false,
        //             });
        //             if (response){
        //                 TriggerAlert("Error",response.data.message,"error");
        //               }
        //               else {
        //                 TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
        //               }
        //         }
        //     },
        //     (error) => {
        //         flag = true; 
        //         this.setState({
        //             showloader: false,
        //             content:
        //                 (error.response &&
        //                     error.response.data &&
        //                     error.response.data.message) ||
        //                 error.message ||
        //                 error.toString(),
        //         });
        //         if (error.response){
        //             TriggerAlert("Error",error.response.data.message,"error");
        //           }
        //           else {
        //             TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
        //           }
        //     },
        //     setTimeout(() => {
        //         if(flag==false){
        //             this.setState({
        //                 showloader:false,
        //             });
        //         TriggerAlert("Error","Response Timed out, Please try again","info");
        //         this.props.history.push('/fpohomeData')             
        //       }
        //     }, 30000)
        // );

        
UserService.getProjectList().then(
          (response) => {
              flag = true;
             
              // var dateDict = {};
              // response.data.date_range.map((date) => {
              //     dateDict[date] = date;
              // });
              this.setState({
                showloader:true,
                getProjectList:[],
                
              })
              this.setState({
                  
                getProjectList: response.data.data == null ? [] : response.data.data,
                
                  showloader: false,
              });
          },
          (error) => {
              flag = true;
              this.setState({
                  showloader: false,
                  content:
                      (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                      error.message ||
                      error.toString(),
              });
              if (error.response) {
                  TriggerAlert("Error", error.response.data.message, "error");
              } else {
                  TriggerAlert(
                      "Error",
                      "Server closed unexpectedly, Please try again",
                      "error"
                  );
              }
          },
          setTimeout(() => {
              if (flag == false) {
                  this.setState({
                      showloader: false,
                  });
                  TriggerAlert("Error", "Response Timed out, Please try again", "info");
                  this.navigateMainBoard()
              }
          }, 30000)
      )

        UserService.getYearRangesNoFpoManager('product_wise', fpoId).then(
          (response) => {
              flag = true;
              // var dateDict = {};
              // response.data.date_range.map((date) => {
              //     dateDict[date] = date;
              // });
              this.setState({
                showloader:true,
                dateRanges:[],
                monthRanges:[],
              })
              this.setState({
                  
                  dateRanges: response.data.sowing_year_range == null ? [] : response.data.sowing_year_range,
                  monthRanges: response.data.months == null ? [] : response.data.months,
                  showloader: false,
              });
          },
          (error) => {
              flag = true;
              this.setState({
                  showloader: false,
                  content:
                      (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                      error.message ||
                      error.toString(),
              });
              if (error.response) {
                  TriggerAlert("Error", error.response.data.message, "error");
              } else {
                  TriggerAlert(
                      "Error",
                      "Server closed unexpectedly, Please try again",
                      "error"
                  );
              }
          },
          setTimeout(() => {
              if (flag == false) {
                  this.setState({
                      showloader: false,
                  });
                  TriggerAlert("Error", "Response Timed out, Please try again", "info");
                  this.navigateMainBoard()
              }
          }, 30000)
      )
    }

    
    createRangeOptions = (ranges) =>
        
        ranges.length
        ? ranges.map((data, i) => 
        (
            <option key={i} name={data} value={data}>
            {data}
            </option>
        ))
        : "";

        YearOptions = (yearList) => {
          if(yearList.length!==''){
            return yearList.map((year, index) =>
          (
              <option key={index} name={year} value={year}>
                  {year}
              </option>
          ))
          }}

    render() {

        const { fpoFIcounts, SelDate, SelSeason, showloader, dateRanges, monthRanges, SelOrg , getProjectList} = this.state;
        console.log('getProjectList', getProjectList)
        const columns = [

          {
              title: "FPO Name",
              field: "farmer_fpo__name",
              cellStyle: {
                minWidth: 300,
                maxWidth: 300
              },
              defaultSort:'asc'
             
              // cellStyle: {
              //     width: "15%"
              // }
          },
          {
              title: "Block",
              field: "fpo_block",
              cellStyle: {
                minWidth: 130,
                maxWidth: 130
              }
          },
          {
              title: "District",
              field: "fpo_dist",
              cellStyle: {
                minWidth: 130,
                maxWidth: 130
              }
          },
          {
              title: "State",
              field: "fpo_state",
              cellStyle: {
                minWidth: 170,
                maxWidth: 170
              }
          },
          {
              title: "Total Farmer (Loan Taken)",
              field: "total_farmers_loan_taken",
              filtering: false,

              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (rowData) => {
                // Check if profits exist and format them, otherwise return 0 formatted
                const profits = rowData.total_farmers_loan_taken == null ? 0 : rowData.total_farmers_loan_taken;
                return new Intl.NumberFormat('en-IN').format(profits);
            },
          },
          {
              title: "Member farmer (Loan Taken)",
              field: "member_farmers_loan_taken",
              filtering:false,

              cellStyle: {
                minWidth: 200,
                maxWidth: 200
              },
              render: (rowData) => {
                const profits = rowData.member_farmers_loan_taken == null ? 0 : rowData.member_farmers_loan_taken;
                return new Intl.NumberFormat('en-IN').format(profits);
            },
          },
          {
              title: "Non-Member Farmer (Loan Taken)",
              field: "non_member_farmers_loan_taken",
              filtering:false,
              cellStyle: {
                minWidth: 200,
                maxWidth: 200
              },
              render: (rowData) => {
                const profits = rowData.non_member_farmers_loan_taken == null ? 0 : rowData.non_member_farmers_loan_taken;
                return new Intl.NumberFormat('en-IN').format(profits);
            },
          },
          {
              title: "FP farmer (Loan Taken)",
              field: "fp_farmers_loan_taken",
              filtering: false,
              render: (rowData) => {
                const profits = rowData.fp_farmers_loan_taken == null ? 0 : rowData.fp_farmers_loan_taken;
                return new Intl.NumberFormat('en-IN').format(profits);
            },
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              }
          },
          {
            title: "Total Farmers (Loan+Input)",
            field: "loan_input_taken",
            filtering:false,
            render: (rowData) => {
            
              const profits = rowData.loan_input_taken == null ? 0 : parseInt(rowData.loan_input_taken);
              return new Intl.NumberFormat('en-IN').format(profits);
          },
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          }
        },
        {
          title: "Total Farmers (Loan+Output)",
          field: "loan_output_taken",
          filtering:false,
          render: (rowData) => {
          
            const profits = rowData.loan_output_taken == null ? 0 : parseInt(rowData.loan_output_taken);
            return new Intl.NumberFormat('en-IN').format(profits);
        },
        cellStyle: {
          minWidth: 150,
          maxWidth: 150
        }
      },
          {
              title: "Total Loan (Input)(In Rs)",
              field: "loan_amount_input_taken",
              filtering:false,
              render: (rowData) => {
                const profits = rowData.loan_amount_input_taken == null ? 0 : parseFloat(rowData.loan_amount_input_taken);
                return new Intl.NumberFormat('en-IN').format(profits);
            },
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              }
          },
          {
              title: "Total Loan (Output) (In Rs)",
              field: "loan_amount_output_taken",
              filtering:false,
              render: (rowData) => {
                const profits = rowData.loan_amount_output_taken == null ? 0 : rowData.loan_amount_output_taken;
                return new Intl.NumberFormat('en-IN').format(profits);
            },
            cellStyle: {
              minWidth: 200,
              maxWidth: 200
            }
          },
        //   {
        //     title: "Total Value (In Rs)",
        //     field: "loan_amount_input_output_taken",
        //     filtering:false,
        //     render: (rowData) => {
            
        //       const profits = rowData.loan_amount_input_output_taken.total_price_sum == null ? 0 : parseInt(rowData.loan_amount_input_output_taken.total_price_sum);
        //       return new Intl.NumberFormat('en-IN').format(profits);
        //   },
            
        //     cellStyle: {
        //      width:"15%"
        //     }
        // },
        
    






      ];

            return (
              <section className="mainWebContentSection">
                <Fragment>
                <div  className="breadcrumb pageBreadCrumbHolder landHoldingBreadCrumbWrap"> 
                  <a
                href="#"
                className="breadcrumb-item pageBreadCrumbItem"
                onClick={() =>this.navigateToPage2("fpohomeData")}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
                  style={{ fontSize: "0.7rem" }}
                />
                &nbsp;Dashboard
              </a>
                  
                  
                  </div>
               
                            
                  <div className="landholdingHeader wrap" style={{marginTop:"-10px"}}>
                    <Row>
                      <Col lg="12" md="12" sm="12" className="noPadding">
                        <div className="padding15 fpoCropsHeading">
                          <Row  style={{marginBottom:'10px'}}>
                            <Col md={6} st>
                              <h4
                                className="farmerListHeading dvaraBrownText "
                                style={{ marginLeft: "30px",fontSize:"24px" ,marginTop:"-10px", marginBottom:"10px"}}
                              >
                               Finance Data

                              </h4>
                            </Col>


                          </Row>
                          <Row>
                            
                                      <Col   className="seasonDropdown" md={8}>
    <Form>
        <Row   style={{marginTop:'-36px', marginLeft:'25px', marginTop:"-20px"}} className="align-items-center">
            <Col xs={12} sm={4} className="mb-2">
                <Form.Group>
                    <Form.Label style={{ fontSize: '15px', color: "brown" }}>
                        Select Organization
                    </Form.Label>
                    <Form.Control
                        size="sm"
                        as="select"
                        value={SelOrg}
                        onChange={this.handleOrgChange}
                        style={{ border: "1px solid grey", color: "brown" }}
                    >
                        <option value="all">All</option>
                        {getProjectList.map(org => (
                            <option key={org.id} value={org.name}>{org.name}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col xs={12} sm={4} className="mb-2">
                <Form.Group>
                    <Form.Label style={{ fontSize: '15px', color: "brown" }}>
                        Select Month
                    </Form.Label>
                    <Form.Control
                        size="sm"
                        as="select"
                        value={SelSeason}
                        onChange={this.handleSeasonChange}
                        style={{ border: "1px solid grey", color: "brown" }}
                    >
                        <option value="all">All</option>
                        {/* <option value="all">All</option> */}
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col xs={12} sm={4} className="mb-2">
                <Form.Group>
                    <Form.Label style={{ fontSize: '15px', color: "brown" }}>
                        Select Year
                    </Form.Label>
                    <Form.Control
                        size="sm"
                        as="select"
                        value={SelDate}
                        onChange={this.handleDateChange}
                        style={{ border: "1px solid grey", color: "brown" }}
                    >
                        <option value="all">All</option>
                        {this.YearOptions(dateRanges)}
                    </Form.Control>
                </Form.Group>
            </Col>
        </Row>
    </Form>
</Col>
                          </Row>
                          </div>
                        {showloader ? (
                          <div className="mainCropsFarmerLoaderWrap">
                            <span className="spinner-border spinner-border-lg mainCropsFarmerLoader"></span>
                          </div>
                        ) : (
                          <MaterialTable
                            icons={tableIcons}
                            title=""
                            style={{ marginLeft: "20px",bottom:'30px', marginTop:"10px"}}
                            data={fpoFIcounts}
                            columns={columns}
                            actions={
                              [
                                // {
                                //     icon: tableIcons.Edit,
                                //     tooltip: 'Edit',
                                //     onClick: (event, rowData) => showModal(true, rowData)
                                // },
                                // rowData => ({
                                //     icon: tableIcons.Delete,
                                //     tooltip: 'Delete',
                                //     isFreeAction: true,
                                //     onClick: (event, rowData) => {if(window.confirm('Are you sure to delete this "'+ rowData.wsp + '" record?'))
                                //                             {this.createWsp(rowData.id)}},
                                //   })
                              ]
                            }
                            options={{
                              maxBodyHeight:500,
                              actionsColumnIndex: -1,
                              doubleHorizontalScroll: true,
                              pageSize: 10,
                              pageSizeOptions: [
                                10,
                                20,
                                50,
                                100,
                                { value: fpoFIcounts.length, label: "All" },
                              ],
                              exportButton: true,

                              headerStyle: {
                                backgroundColor: "#A3C614",
                                color: "#fff",
                                fontSize: "1.2rem",
                                fontFamily: "barlow_reg",
                                fontWeight: "bold",
                              },
                              rowStyle: {
                                backgroundColor: "#f1f1f1",
                                borderBottom: "2px solid #e2e2e2",
                                fontSize: "0.9rem",
                              },
                              filtering: true,
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="verticalSpacer20"></div>
                </Fragment>
              </section>
            );
        
    }
}