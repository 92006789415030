import React, { Component, Fragment, useState } from "react";
// import { Redirect, Route, Link } from 'react-router-dom';
import UserService from "../services/user.service";
import "../assets/css/crops.css";
import MaterialTable from "material-table";
import tableIcons from './icons';
import {TriggerAlert,} from './dryfunctions';
import {
 
    Row,
    Col,
    Form
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthService from "../services/auth.service";
 
import { faHtml5, faHome } from "@fortawesome/free-solid-svg-icons";
//have used class component . Initially we are defining all the state variables which are used inside the component.
 
export default class FpoInput extends Component {
    constructor(props) {
        super(props)
        // this.handleDateChange = this.handleDateChange.bind(this);
        // this.handleSeasonChange = this.handleSeasonChange.bind(this);
       
        this.state = {
            fpoInputcounts: [],
            loading: false,
            SelDate: "all",
            SelSeason: "all",
            showloader:true,
            getProjectList:[],
            selOrg:'all',
            isManagerLogged:false,
            

        };
    }
    // handleDateChange = (e) => {
    //     this.setState(
    //       {
    //         SelDate: e.target.value,
    //         fpoInputcounts: []
    //       }
    //       );
    //       this.fetchCropsCount(e.target.value, this.state.SelSeason)
    // }
    // handleSeasonChange = (e) => {
    //     this.setState(
    //       {
    //         SelSeason: e.target.value,
    //         fpoInputcounts: []
    //       }
    //     )
    //     this.fetchCropsCount(this.state.SelDate, e.target.value)
    // }

    handleOrgChange = (e) => {
      const selectedOrgName = e.target.value;
      
      // Find the selected organization by name
      const selectedOrg = this.state.getProjectList.find(org => org.name === selectedOrgName);
      
      // Update state with selected organization details
      this.setState({
        SelOrg: selectedOrgName, // Store the selected name
        SelOrgId: selectedOrg ? selectedOrg.id : null, // Store the selected id
        fpoFIcounts: [],
        showloader: true,
        
      });
    
      // Call fetchCropsCount with the selected organization's id
      this.fetchCropsCount(selectedOrg ? selectedOrg.id : 'all');
    }
    

    fetchCropsCount = (org) => {
      var flag =false;
      this.setState({showloader:false,
        fpoInputcounts:[],
      })

      UserService.getFpoInputsCount(org).then(
        (response) => {
            flag = true;
            if (response.data.success) {
                this.setState({
                    fpoInputcounts: response.data.fpo_inputs_data,
                    showloader:false,
                });
            }
            else {
                this.setState({
                showloader:false,})
                if (response){
                    TriggerAlert("Error",response.data.message,"error");
                  }
                  else {
                    TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
                  }
            }
        },
        (error) => {
            flag = true;
            this.setState({
                showloader:false,
                content:
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString(),
            });
            if (error.response){
                TriggerAlert("Error",error.response.data.message,"error");
              }
              else {
                TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
              }
        },
        setTimeout(() => {
            if(flag==false){
                this.setState({
                    showloader:false,
                });
            TriggerAlert("Error","Response Timed out, Please try again","info");
            this.props.history.push('/fpohomeData')             
            }
        }, 30000)
    );


    }
    // on clicking on FPO Name in pivot table this function is trigerred . Here we are storing the fpoName and fpoId in localStorage
     //then with that fpoName we are navigating to different component and changing the URL also.
    navigateToPage= (pageName,fpoId,fpoName) => {
      localStorage.setItem("fpoId", JSON.stringify(fpoId));
      localStorage.setItem("fpoName", fpoName);

      this.props.history.push(
        "/inputs/" +
          fpoName
      );
        // this.props.history.push("/" + pageName + "");
      };
                      //this function will navigate to Organization Dahboard.

      navigateToPage2= (pageName) => {
       
        this.props.history.push("/" + pageName + "");
      };
   //in componentDidMount initially we are checking if it is a valid user or not .Then we are checking if valid user is parent or not then accordinly we are 
    // navigating to the component. Then we are calling API.
    componentDidMount() {
     
      const user = AuthService.getCurrentUser();
     
      if(user.is_project_manager){
        this.setState({
          isManagerLogged:true,
        })
      }

      if(!user){
        this.props.history.push('/')
        return
      }
      if(!user.is_parent && !user.is_project_manager)
        this.props.history.push("/dashboard")

      this.fetchCropsCount('all')
    
      var flag = false;
      UserService.getProjectList().then(
        (response) => {
            flag = true;
            // var dateDict = {};
            // response.data.date_range.map((date) => {
            //     dateDict[date] = date;
            // });
            
            this.setState({
                
              getProjectList: response.data.data== null ? [] : response.data.data,
              
                showloader: false,
            });
        },
        (error) => {
            flag = true;
            this.setState({
                showloader: false,
                content:
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString(),
            });
            if (error.response) {
                TriggerAlert("Error", error.response.data.message, "error");
            } else {
                TriggerAlert(
                    "Error",
                    "Server closed unexpectedly, Please try again",
                    "error"
                );
            }
        },
        setTimeout(() => {
            if (flag == false) {
                this.setState({
                    showloader: false,
                });
                TriggerAlert("Error", "Response Timed out, Please try again", "info");
                this.navigateMainBoard()
            }
        }, 30000)
    )
   
    }
    // createRangeOptions = (ranges) =>
       
    //     ranges.length
    //     ? ranges.map((data, i) =>
    //     (
    //         <option key={i} name={data} value={data}>
    //         {data}
    //         </option>
    //     ))
    //     : "";
 
    //rendering the materialTable.
    render() {

        const { fpoInputcounts, SelDate, SelSeason, showloader, SelOrg, getProjectList } = this.state;
        const columns = [
 
            {
                title: "FPO Name",
                field: "name",
                cellStyle:{
                  minWidth: 350,
                  maxWidth: 350
                },
                render: (rowData) => {
                  let fpoId = rowData.id;
                  let fpoName = rowData.name;
                  return (
                    <div
                      onClick={() =>
                        this.navigateToPage("inputs",fpoId,fpoName)
                         
                      }
                    >
                      <a href="#!">{rowData.name}</a>
                    </div>
                  );
       
                },
                // cellStyle: {
                //     width: "15%"
                // }
            },
            {
                title: "Block",
                field: "fpo_block",
                cellStyle: {
                    width: "15%"
                }
            },
            {
                title: "District",
                field: "fpo_dist",
                cellStyle: {
                    width: "15%"
                }
            },
            {
                title: "State",
                field: "fpo_state",
                cellStyle: {
                    width: "15%"
                }
            },
            {
                title: "Total Inputs Listed(sales)",
                field: "input_product_count",
                filtering: false,
 
                cellStyle: {
                    width: "15%"
                },
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.input_product_count == null ? 0 : rowData.input_product_count;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
            },
            {
                title: "Rental Equipments Available",
                field: "farm_equipment_count",
                filtering:false,
 
                cellStyle: {
                    width: "15%"
                },
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.farm_equipment_count == null ? 0 : rowData.farm_equipment_count;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
            },
            // {
            //     title: "Season",
            //     // field: "crop_count",
            //     // filtering:false,
            //     lookup: { "Zaid": "Zaid", "Rabi": 'Rabi', "Kharif": 'Kharif' },
            //     cellStyle: {
            //         width: "15%"
            //     }
            // },
 
        ];
       
            return (
              <section className="mainWebContentSection">
                <Fragment>
                <div  className="breadcrumb pageBreadCrumbHolder landHoldingBreadCrumbWrap">
                  <a
                href="#"
                className="breadcrumb-item pageBreadCrumbItem"
                onClick={() =>this.navigateToPage2("fpohomeData")
                }
              >
                <FontAwesomeIcon
                  icon={faHome}
                  className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
                  style={{ fontSize: "0.7rem" }}
                />
                &nbsp;Dashboard
              </a>
                 
                 
                  </div>
                  <div className="landholdingHeader wrap">
                    <Row>
                      <Col lg="12" md="12" sm="12" className="noPadding">
                      <div className="padding15 fpoCropsHeading">
                      <Row style={{ marginBottom: "20px", marginTop:"-20px" }}>
    <Col md={6}> {/* Changed from md={4} to md={6} */}
      <h4
        className="farmerListHeading dvaraBrownText"
        style={{ marginLeft: "30px", fontSize: "24px" , marginTop:"-80%",marginBottom:"10px"}}
        >
          {this.state.isManagerLogged ? "Inputs Data" : "Organization Inputs Data"}
     
      </h4>
    </Col>
  </Row>
  <Row>
    {this.state.isManagerLogged ? (
      <Col className="seasonDropdown" md={8}>
        <Form>
          <Row style={{ marginTop: "-36px", marginLeft: "25px" }} className="align-items-center">
            <Col xs={12} sm={4} className="mb-2"> {/* Changed from xs={6} sm={6} to xs={12} sm={4} */}
              <Form.Group>
                <Form.Label style={{ fontSize: "15px", color: "brown" }}>
                  Select Organization
                </Form.Label>
                <Form.Control
                  size="sm"
                  as="select"
                  value={SelOrg}
                  onChange={this.handleOrgChange}
                  style={{ border: "1px solid grey", color: "brown" }}
                >
                  <option value="all">All</option>
                  {getProjectList.map(org => (
                    <option key={org.id} value={org.name}>
                      {org.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Col>
    ) : (
      ""
    )}
  </Row>
</div>


                        {showloader ? (
                          <div className="mainCropsFarmerLoaderWrap">
                            <span className="spinner-border spinner-border-lg mainCropsFarmerLoader"></span>
                          </div>
                        ) : (
                          <MaterialTable
                            icons={tableIcons}
                            title=""
                            style={{ marginLeft: "30px" , bottom:'30px', marginTop:"10px"}}
                            data={fpoInputcounts}
                            columns={columns}
                            actions={
                              [
                                // {
                                //     icon: tableIcons.Edit,
                                //     tooltip: 'Edit',
                                //     onClick: (event, rowData) => showModal(true, rowData)
                                // },
                                // rowData => ({
                                //     icon: tableIcons.Delete,
                                //     tooltip: 'Delete',
                                //     isFreeAction: true,
                                //     onClick: (event, rowData) => {if(window.confirm('Are you sure to delete this "'+ rowData.wsp + '" record?'))
                                //                             {this.createWsp(rowData.id)}},
                                //   })
                              ]
                            }
                            options={{
                              maxBodyHeight:500,
                              actionsColumnIndex: -1,
                              doubleHorizontalScroll: true,
                              pageSize: 10,
                              pageSizeOptions: [
                                10,
                                20,
                                50,
                                100,
                                { value: fpoInputcounts.length, label: "All" },
                              ],
                              exportButton: true,
 
                              headerStyle: {
                                backgroundColor: "#A3C614",
                                color: "#fff",
                                fontSize: "1.2rem",
                                fontFamily: "barlow_reg",
                                fontWeight: "bold",
                              },
                              rowStyle: {
                                backgroundColor: "#f1f1f1",
                                borderBottom: "2px solid #e2e2e2",
                                fontSize: "0.9rem",
                              },
                              filtering: true,
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="verticalSpacer20"></div>
                </Fragment>
              </section>
            );
       
    }
}
