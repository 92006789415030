import React, { Component, Fragment, useState } from "react";
import { Redirect, Route, Link } from 'react-router-dom';
import UserService from "../services/user.service";
import "../assets/css/crops.css";
import MaterialTable from "material-table";
import tableIcons from './icons';
import {TriggerAlert,} from './dryfunctions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthService from "../services/auth.service";

import { faHtml5, faHome } from "@fortawesome/free-solid-svg-icons";
import {

    Row,
    Col,
    Form
} from "react-bootstrap";
//have used class component . Initially we are defining all the state variables which are used inside the component.

export default class FpoFarmerOrder extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
            fpoOrdercounts: [],
            loading: false,
            showloader:true,
            getProjectlist:[],
            SelOrg:'all',
            isManagerlogged:false,
            Selyear:'all',
            dateRanges:[],
            SelOrgId:'',
            SelStatus:'all',

           
        };
    }
    // handleDateChange = (e) => {
    //     this.setState(
    //       {
    //         SelDate: e.target.value,
    //         fpoOrdercounts: []
    //       }
    //       );
    //       this.fetchCropsCount(e.target.value, this.state.SelSeason)
    // }
    // handleSeasonChange = (e) => {
    //     this.setState(
    //       {
    //         SelSeason: e.target.value,
    //         fpoOrdercounts: []
    //       }
    //     )
    //     this.fetchCropsCount(this.state.SelDate, e.target.value)
    // }
    handleOrgChange = (e) => {
      const selectedOrgName = e.target.value;
      
      // Find the selected organization by name
      const selectedOrg = this.state.getProjectlist.find(org => org.name === selectedOrgName);
      
      // Update state with selected organization details
      this.setState({
        SelOrg: selectedOrgName, // Store the selected name
        SelOrgId: selectedOrg ? selectedOrg.id : null, // Store the selected id
        fpoOrdercounts: [],
        showloader: true,
        
      });

     
    
      // Call fetchCropsCount with the selected organization's id
      this.fetchCropsCount(selectedOrg ? selectedOrg.id : 'all', this.state.Selyear, this.state.SelStatus);
    }

    handleYearChange=(e)=>{
      this.setState({
        Selyear:e.target.value,
        fpoOrdercounts:[],
        showloader:true
      })
      this.fetchCropsCount(this.state.SelOrgId?this.state.SelOrgId:'all', e.target.value, this.state.SelStatus)
    }
    
    handleStatusChange=(e)=>{
      this.setState({
        SelStatus:e.target.value,
        fpoOrdercounts:[],
        showloader:true
      })
      this.fetchCropsCount(this.state.SelOrgId?this.state.SelOrgId:'all', this.state.Selyear, e.target.value)
    }

    fetchCropsCount = (org, year, status) => {
      var flag=false;
      this.setState({
        showloader:false,
        fpoOrdercounts:[],
      })
      UserService.getFpoOrdersCount(org, year, status).then(
        (response) => {
            flag = true;
            if (response.data.success) {
                this.setState({
                    showloader:false,
                    fpoOrdercounts: response.data.fpo_orders_data,

                });
            }
            else {
                this.setState({showloader:false,})
                if (response){
                    TriggerAlert("Error",response.data.message,"error");
                  }
                  else {
                    TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
                  }
            }
        },
        (error) => {
            flag = true; 
            this.setState({
                showloader:false,
                content:
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString(),
            });
            if (error.response){
                TriggerAlert("Error",error.response.data.message,"error");
              }
              else {
                TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
              }
        },
        setTimeout(() => {
            if(flag==false){
                this.setState({
                    showloader:false,
                });
            TriggerAlert("Error","Response Timed out, Please try again","info");
            this.props.history.push('/fpohomeData')             
            }
        }, 30000)
    );

    }

     // on clicking on FPO Name in pivot table this function is trigerred . Here we are storing the fpoName and fpoId in localStorage
     //then with that fpoName we are navigating to different component and changing the URL also.
      navigateToPage= (pageName,fpoId,fpoName) => {
        localStorage.setItem("fpoId", JSON.stringify(fpoId));
        localStorage.setItem("fpoName", JSON.stringify(fpoName));

  
        this.props.history.push("/inputorders/" + fpoName);

      };
        //this function will navigate to Organization Dahboard.

      navigateToPage2= (pageName) => {
       
        this.props.history.push("/" + pageName + "");
      };

      //in componentDidMount initially we are checking if it is a valid user or not .Then we are checking if valid user is parent or not then accordinly we are 
      // navigating to the component. Then we are calling API.
    componentDidMount() {
      const user = AuthService.getCurrentUser();
      const fpoId = localStorage.getItem("fpoId")
    
      if(user.is_project_manager){
        this.setState({
          isManagerlogged:true,
        })
      }

      if(!user){
        this.props.history.push('/')
        return
      }
      if(!user.is_parent && !user.is_project_manager)
        this.props.history.push("/dashboard")
      
        var flag = false;

     {user.is_project_manager? this.fetchCropsCount('all', 'all', 'all'):
      
  UserService.getFpoOrdersCountParent().then(
    (response) => {
        flag = true;
        if (response.data.success) {
            this.setState({
                showloader:false,
                fpoOrdercounts: response.data.fpo_orders_data,

            });
        }
        else {
            this.setState({showloader:false,})
            if (response){
                TriggerAlert("Error",response.data.message,"error");
              }
              else {
                TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
              }
        }
    },
    (error) => {
        flag = true; 
        this.setState({
            showloader:false,
            content:
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString(),
        });
        if (error.response){
            TriggerAlert("Error",error.response.data.message,"error");
          }
          else {
            TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
          }
    },
    setTimeout(() => {
        if(flag==false){
            this.setState({
                showloader:false,
            });
        TriggerAlert("Error","Response Timed out, Please try again","info");
        this.props.history.push('/fpohomeData')             
        }
    }, 30000)
);
     }

      UserService.getProjectList().then(
        (response) => {
            flag = true;
            // var dateDict = {};
            // response.data.date_range.map((date) => {
            //     dateDict[date] = date;
            // });
            
            this.setState({
                
              getProjectlist: response.data.data== null ? [] : response.data.data,
              
                showloader: false,
            });
        },
        (error) => {
            flag = true;
            this.setState({
                showloader: false,
                content:
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString(),
            });
            if (error.response) {
                TriggerAlert("Error", error.response.data.message, "error");
            } else {
                TriggerAlert(
                    "Error",
                    "Server closed unexpectedly, Please try again",
                    "error"
                );
            }
        },
        setTimeout(() => {
            if (flag == false) {
                this.setState({
                    showloader: false,
                });
                TriggerAlert("Error", "Response Timed out, Please try again", "info");
                this.navigateMainBoard()
            }
        }, 30000)
    )
       
  if(user.is_parent){
    UserService.getYearRangesNoFpo('product_wise').then(
      (response) => {
          flag = true;
          // var dateDict = {};
          // response.data.date_range.map((date) => {
          //     dateDict[date] = date;
          // });
          this.setState({
              
              dateRanges: response.data.sowing_year_range == null ? [] : response.data.sowing_year_range,
              monthRanges: response.data.months == null ? [] : response.data.months,
              showloader: false,
          });
      },
      (error) => {
          flag = true;
          this.setState({
              showloader: false,
              content:
                  (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                  error.message ||
                  error.toString(),
          });
          if (error.response) {
              TriggerAlert("Error", error.response.data.message, "error");
          } else {
              TriggerAlert(
                  "Error",
                  "Server closed unexpectedly, Please try again",
                  "error"
              );
          }
      },
      setTimeout(() => {
          if (flag == false) {
              this.setState({
                  showloader: false,
              });
              TriggerAlert("Error", "Response Timed out, Please try again", "info");
              this.navigateMainBoard()
          }
      }, 30000)
  )
  }else{
    UserService.getYearRangesNoFpoManager('product_wise').then(
      (response) => {
          flag = true;
          // var dateDict = {};
          // response.data.date_range.map((date) => {
          //     dateDict[date] = date;
          // });
          this.setState({
              
              dateRanges: response.data.sowing_year_range == null ? [] : response.data.sowing_year_range,
              monthRanges: response.data.months == null ? [] : response.data.months,
              showloader: false,
          });
      },
      (error) => {
          flag = true;
          this.setState({
              showloader: false,
              content:
                  (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                  error.message ||
                  error.toString(),
          });
          if (error.response) {
              TriggerAlert("Error", error.response.data.message, "error");
          } else {
              TriggerAlert(
                  "Error",
                  "Server closed unexpectedly, Please try again",
                  "error"
              );
          }
      },
      setTimeout(() => {
          if (flag == false) {
              this.setState({
                  showloader: false,
              });
              TriggerAlert("Error", "Response Timed out, Please try again", "info");
              this.navigateMainBoard()
          }
      }, 30000)
  )
  }


     
    }

    // createRangeOptions = (ranges) =>
        
    //     ranges.length
    //     ? ranges.map((data, i) => 
    //     (
    //         <option key={i} name={data} value={data}>
    //         {data}
    //         </option>
    //     ))
    //     : "";

    YearOptions = (yearList) => {
      if(yearList.length!==''){
        return yearList.map((year, index) =>
      (
          <option key={index} name={year} value={year}>
              {year}
          </option>
      ))
      }}

    render() {

        const {dateRanges, fpoOrdercounts, SelDate, SelSeason, showloader, SelOrg, getProjectlist, Selyear  , SelStatus} = this.state;
        const columns = [

            {
                title: "FPO Name",
                field: "name",
                cellStyle: {
                  minWidth: 350,
                  maxWidth: 350
                },
                render: (rowData) => {
                  let fpoId = rowData.id;
                  let fpoName=rowData.name;
                  return (
                    <div
                      onClick={() =>
                        this.navigateToPage("inputorders",fpoId,fpoName)
                         
                      }
                    >
                      <a href="#!">{rowData.name}</a>
                    </div>
                  );
        
                },
                // cellStyle: {
                //     width: "15%"
                // }
            },
            {
                title: "Block",
                field: "fpo_block",
                cellStyle: {
                    width: "15%"
                }
            },
            {
                title: "District",
                field: "fpo_dist",
                cellStyle: {
                    width: "15%"
                }
            },
            {
                title: "State",
                field: "fpo_state",
                cellStyle: {
                    width: "15%"
                }
            },
            {
                title: "Total Orders",
                field: "total_orders",
                filtering: false,

                cellStyle: {
                    width: "15%"
                },
                // render: rowData=>rowData.total_orders.toLocaleString()
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.total_orders == null ? 0 : rowData.total_orders;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
            },
            {
                title: "Total Qty",
                field: "total_qty",
                filtering:false,
                cellStyle: {
                    width: "15%"
                },
                // render: rowData => rowData.total_qty.toLocaleString()
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.total_qty == null ? 0 : rowData.total_qty;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
            },
            {
              title: "Total Value (In Rs)",
              field: "total_value",
              filtering: false,
              cellStyle: {
                width: "15%"
              },
              render: rowData => new Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: 'INR'
              }).format(rowData.total_value)
            },

        ];
       
            return (
              <section className="mainWebContentSection">
                <Fragment>
                <div  className="breadcrumb pageBreadCrumbHolder landHoldingBreadCrumbWrap"> 
                  <a
                href="#"
                className="breadcrumb-item pageBreadCrumbItem"
                onClick={() =>this.navigateToPage2("fpohomeData")}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
                  style={{ fontSize: "0.7rem" }}
                />
                &nbsp;Dashboard
              </a>
                  
                  
                  </div>
                  <div className="landholdingHeader wrap">
                    <Row>
                      <Col lg="12" md="12" sm="12" className="noPadding">
                        <div className=" padding15 fpoCropsHeading">
                        <Row style={{ marginBottom: "20px", marginTop:"-20px" }}>
                            <Col md={6} st>
                              <h4
                                className="farmerListHeading dvaraBrownText "
                                style={{ marginLeft: "30px", fontSize: "24px" , marginTop:"-80%",marginBottom:"10px"}}
                                >
                            {this.state.isManagerlogged ?"Farmer Orders Data": " Organization Farmer Orders Data"}
                              </h4>
                            </Col>


                          </Row>
                          <Row>
    {this.state.isManagerlogged ? (
        <Col className="seasonDropdown" md={8}>
            <Form>
                <Row style={{ marginTop: '-36px', marginLeft: '25px' }} className="align-items-center">
                    
                    {/* Select Organization */}
                    <Col xs={12} sm={4} className="mb-2">
                        <Form.Group>
                            <Form.Label style={{ fontSize: '15px', color: "brown" }}>
                                Select Organization
                            </Form.Label>
                            <Form.Control
                                size="sm"
                                as="select"
                                value={SelOrg}
                                onChange={this.handleOrgChange}
                                style={{ border: "1px solid grey", color: "brown" }}
                            >
                                <option value="all">All</option>
                                {getProjectlist.map(org => (
                                    <option key={org.id} value={org.name}>{org.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    {/* Select Year */}
                    <Col xs={12} sm={4} className="mb-2">
                        <Form.Group>
                            <Form.Label style={{ fontSize: '15px', color: "brown" }}>
                                Select Year
                            </Form.Label>
                            <Form.Control
                                size="sm"
                                as="select"
                                value={Selyear}
                                onChange={this.handleYearChange}
                                style={{ border: "1px solid grey", color: "brown" }}
                            >
                                <option value="all">All</option>
                                {this.YearOptions(dateRanges)}
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    {/* Order Status */}
                    <Col xs={12} sm={4} className="mb-2">
                        <Form.Group>
                            <Form.Label style={{ fontSize: '15px', color: "brown" }}>
                                Order Status
                            </Form.Label>
                            <Form.Control
                                size="sm"
                                as="select"
                                value={SelStatus}
                                onChange={this.handleStatusChange}
                                style={{ border: "1px solid grey", color: "brown" }}
                            >
                                <option value="all">All</option>
                                <option value="Pending">Pending</option>
                                <option value="Accepted">Accepted</option>
                                <option value="Completed">Completed</option>
                                <option value="Failed">Failed</option>
                                <option value="Rejected">Rejected</option>
                                <option value="Cancelled">Cancelled</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </Col>
    ) : ''}
</Row>

                        </div>
                        {showloader ? (
                          <div className="mainCropsFarmerLoaderWrap">
                            <span className="spinner-border spinner-border-lg mainCropsFarmerLoader"></span>
                          </div>
                        ) : (
                          <MaterialTable
                            icons={tableIcons}
                            style={{ marginLeft: "30px" , bottom:'30px', marginTop:"20px"}}
                            title=""
                            data={fpoOrdercounts}
                            columns={columns}
                            actions={
                              [
                                // {
                                //     icon: tableIcons.Edit,
                                //     tooltip: 'Edit',
                                //     onClick: (event, rowData) => showModal(true, rowData)
                                // },
                                // rowData => ({
                                //     icon: tableIcons.Delete,
                                //     tooltip: 'Delete',
                                //     isFreeAction: true,
                                //     onClick: (event, rowData) => {if(window.confirm('Are you sure to delete this "'+ rowData.wsp + '" record?'))
                                //                             {this.createWsp(rowData.id)}},
                                //   })
                              ]
                            }
                            options={{
                              maxBodyHeight:500,
                              actionsColumnIndex: -1,
                              doubleHorizontalScroll: true,
                              pageSize: 10,
                              pageSizeOptions: [
                                10,
                                20,
                                50,
                                100,
                                { value: fpoOrdercounts.length, label: "All" },
                              ],
                              exportButton: true,

                              headerStyle: {
                                backgroundColor: "#A3C614",
                                color: "#fff",
                                fontSize: "1.2rem",
                                fontFamily: "barlow_reg",
                                fontWeight: "bold",
                              },
                              rowStyle: {
                                backgroundColor: "#f1f1f1",
                                borderBottom: "2px solid #e2e2e2",
                                fontSize: "0.9rem",
                              },
                              filtering: true,
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="verticalSpacer20"></div>
                </Fragment>
              </section>
            );
        
    }
}