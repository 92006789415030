import React, { ReactDOM,Component, Fragment, useState } from "react";
import { Redirect, Route, Link } from 'react-router-dom';
import UserService from "../services/user.service";
import "../assets/css/crops.css";
import MaterialTable from "material-table";
import tableIcons from './icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Row, Col } from "react-bootstrap";
import {TriggerAlert,} from './dryfunctions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthService from "../services/auth.service";

import { faHtml5, faHome } from "@fortawesome/free-solid-svg-icons";
import { Hidden } from "@material-ui/core";
//have used class component . Initially we are defining all the state variables which are used inside the component.

var stickyposition;
export default class FpoList extends Component {
    constructor(props) {
        super(props)
        this.fpoListTableWrapRef=React.createRef();
        this.state = {
            fpolist: [],           
            loading: false,
            showloader:true,
           

        };
    }
    //this function will navigate to Organization Dahboard.
    navigateToPage2= (pageName) => {
       
        this.props.history.push("/" + pageName + "");
      };
    //in componentDidMount initially we are checking if it is a valid user or not .Then we are checking if valid user is parent or not then accordinly we are 
    // navigating to the component. Then we are calling the API and fetching the data and if API returns error then we are displaying an error messge .
    componentDidMount() {
        // stickyposition=this.fpoListTableWrapRef.current;

        // if(!localStorage.getItem('user')){
        //     this.props.history.push('/')
        //     return
        //   }
        // window.addEventListener("scroll",()=>{
        //      let tabWrapElement=document.getElementById("tableWrap");
        //     //  tabWrapElement.style.top=Math.max(0,250-this.scrollTop());
        //     tabWrapElement.style.position="fixed";
        //     // tabWrapElement.style.top=0;


        // })
        // let stickyElem = document.getElementById("tableWrap");
        // ReactDOM.findDOMNode(this.refs["fpoListTableWrapRef"]).getBoundingClientRect();

    //    let currStickyPos = stickyElem.getBoundingClientRect().top + window.pageYOffset;
    //     window.onscroll = function() {
            
          
    //         if(window.pageYOffset > currStickyPos) {
    //             stickyElem.style.position = "fixed";
    //             stickyElem.style.top = "0px";
    //         } else {
    //             stickyElem.style.position = "relative";
    //             stickyElem.style.top = "initial";
	// 	}

    // }


        const user = AuthService.getCurrentUser();
        if(!user){
          this.props.history.push('/')
          return
        }
        if(!user.is_parent && !user.is_project_manager)
          this.props.history.push("/dashboard")
        

        var flag = false;
        UserService.getFpoList().then(
            (response) => {
                flag = true; 
                // debugger
                if (response.data.success) {
                    this.setState({
                        showloader:false,
                        fpolist: response.data.fpo_data
                    });
                }
                else {
                    this.setState({showloader:false,})
                    if (response){
                        TriggerAlert("Error",response.data.message,"error");
                      }
                      else {
                        TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
                      }
                }
            },
            (error) => {
                flag = true;
                this.setState({
                    showloader:false,
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString(),
                });
                if (error.response){
                    
                    TriggerAlert("Error",error.response.data.message,"error");
                  }
                  else {
                    TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
                  }
            },
            setTimeout(() => {
                if(flag==false){
                    this.setState({
                        showloader:false,
                    });
                TriggerAlert("Error","Response Timed out, Please try again","info");
                this.props.history.push('/fpohomeData')             
                }
            }, 30000)

        )
    }
 // rendering material table data.
    
//  stickyposition=ReactDOM.findDOMNode(this.fpoListTableWrapRef.current).getBoundingClientRect();

// stickyposition=this.fpoListTableWrapRef.current.getBoundingClientRect();

    render() {
      
        const { fpolist, showloader } = this.state;

        const columns = [
            {
                title: "FPO Name",
                field: "farmer_fpo__name",
                filtering: false,
                headerStyle: {
                    position: "sticky",
                    top: 0,
                    zIndex: 1000,
                    fontWeight: "bold",
                },
                cellStyle: {
                    position: "sticky",
                    background: "#f1f1f1",
                    left: 0,
                    zIndex: 1,
                    minWidth: 350, // Increased width
                    maxWidth: 350
                },
            },
            {
                title: "Block",
                field: "fpo_block",
                filtering: false,
                cellStyle: {
                    minWidth: 130, // Increased width
                },
            },
            {
                title: "District",
                field: "fpo_dist",
                filtering: false,
                cellStyle: {
                    minWidth: 130, // Increased width
                },
            },
            {
                title: "State",
                field: "fpo_state",
                filtering: false,
                cellStyle: {
                    minWidth: 130, // Increased width
                },
            },
            {
                title: "Licenses",
                field: "farmer_fpo__seed_license",
                render: rowData => (
                    <ul>
                        <li>Seed: <span className="darkGreenText">{rowData.farmer_fpo__seed_license ? "YES" : "NO"}</span></li>
                        <li>AgroChemical: <span className="darkGreenText">{rowData.farmer_fpo__agrochem_license ? "YES" : "NO"}</span></li>
                        <li>Fertilizer: <span className="darkGreenText">{rowData.farmer_fpo__fert_license ? "YES" : "NO"}</span></li>
                        <li>APMC: <span className="darkGreenText">{rowData.farmer_fpo__apmc_license ? "YES" : "NO"}</span></li>
                    </ul>
                ),
                filtering: false,
                cellStyle: {
                    minWidth: 250, // Increased width
                },
            },
            {
                title: "Share Cap",
                field: "last_year_share_cap",
                filtering: false,
                render: rowData => {
                    const profits = rowData.last_year_share_cap == null ? 0 : rowData.last_year_share_cap;
                    return new Intl.NumberFormat('en-IN').format(profits);
                },
                cellStyle: {
                    minWidth: 130, // Increased width
                },
            },
            {
                title: "Yearly Turn-over(Last FY)(INR)",
                field: "fpo_turnover.turn_over",
                filtering: false,
                render: rowData => {
                    const profits = rowData.fpo_turnover.turn_over == null ? 0 : rowData.fpo_turnover.turn_over;
                    return new Intl.NumberFormat('en-IN').format(profits);
                },
                cellStyle: {
                    minWidth: 130, // Increased width
                },
            },
            {
                title: "Profits(Last FY)(INR)",
                field: "fpo_turnover.profits",
                filtering: false,
                render: rowData => {
                    const profits = rowData.fpo_turnover.profits == null ? 0 : rowData.fpo_turnover.profits;
                    return new Intl.NumberFormat('en-IN').format(profits);
                },
                cellStyle: {
                    minWidth: 130, // Increased width
                },
            },
            {
                title: "Established",
                field: "farmer_fpo__establishment_date",
                filtering: false,
                render: rowData => (rowData.farmer_fpo__establishment_date ? rowData.farmer_fpo__establishment_date : "NA"),
                cellStyle: {
                    minWidth: 130, // Increased width
                },
            },
            {
                title: "Staff(CEO, Accountant, Others)",
                field: "farmer_fpo__staff_count",
                filtering: false,
                render: rowData => (
                    <ul>
                        <li>CEO: {rowData.farmer_fpo__ceo_count}</li>
                        <li>Accountant: {rowData.farmer_fpo__accountant_count}</li>
                        <li>Others: {rowData.farmer_fpo__other_count}</li>
                        <li>Staff: {rowData.farmer_fpo__staff_count}</li>
                    </ul>
                ),
                cellStyle: {
                    minWidth: 130, // Increased width
                },
            },
            {
                title: "Using our accounting platform",
                field: "accounting_service",
                filtering: false,
                render: rowData => (rowData.accounting_service ? "YES" : "NO"),
                cellStyle: {
                    minWidth: 130, // Increased width
                },
            },
            {
                title: "Using our service for accounting entries",
                field: "farmer_fpo__der_acc_entry",
                filtering: false,
                render: rowData => (rowData.farmer_fpo__der_acc_entry ? "YES" : "NO"),
                cellStyle: {
                    minWidth: 130, // Increased width
                },
            },
            {
                title: "Total farmers with Mobile app",
                field: "app_farmers",
                filtering: false,
                cellStyle: {
                    minWidth: 130, // Increased width
                },
            },
        ];
        
        
            return (
                <section className="mainWebContentSection">
                    <Fragment>
                    <div  className="breadcrumb pageBreadCrumbHolder landHoldingBreadCrumbWrap"> 
                  <a
                href="#"
                className="breadcrumb-item pageBreadCrumbItem"
                onClick={() =>this.navigateToPage2("fpohomeData")
                }
              >
                <FontAwesomeIcon
                  icon={faHome}
                  className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
                  style={{ fontSize: "0.7rem" }}
                />
                &nbsp;Dashboard
              </a>
                  
                  
                  </div>
                        <div className="landholdingHeader wrap">
                            <Row>
                                <Col lg="12" md="12" sm="12" className="noPadding">
                                    <div className="PageHeading padding15">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="fpoListHeading dvaraBrownText" style={{ marginLeft: "25px", fontSize: "24px" , marginTop:"-30px"}}>
                                                      {this.state.is_project_manager? "List Data": "Organization List Data"}
                                                </h4>
                                            </Col>

                                            
                                        </Row>
                                       
                                    </div>
                                    {showloader ?
                                        (

                                            <div className="mainCropsFarmerLoaderWrap">
                                                <span className="spinner-border spinner-border-lg mainCropsFarmerLoader"></span>
                                            </div>
                                        ) :

                                        ( <div id="tableWrap" ref={this.fpoListTableWrapRef} style={{position:"sticky",top:"0px",height:"400px"}}> 
                                        
                                        <MaterialTable icons={tableIcons}

                                            title=""
                                            style={{ marginLeft: "30px", marginBottom: "20px", marginTop:"60px" }}
                                            data={fpolist}
                                            columns={columns}
                                            actions={[
                                            ]}
                                            options={{
                                                maxBodyHeight:"500px",
                                                actionsColumnIndex: -1,
                                                doubleHorizontalScroll: true,
                                                pageSize: 10,
                                                pageSizeOptions: [10, 20, 50, 100, { value: fpolist.length, label: 'All' }],
                                                exportButton: true,
                                               
                                                headerStyle: {
                                                    backgroundColor: '#A3C614',
                                                    color: '#fff',
                                                    fontSize: '1.2rem',
                                                    fontFamily: 'barlow_reg',
                                                    fontWeight: 'bold',
                                                    position: 'sticky',
                                                    top:0,
                                                    overflow:"hidden",
                                                    left: 0,
                                                   
                                                },
                                                
                                                rowStyle: {
                                                    backgroundColor: '#f1f1f1',
                                                    borderBottom: '2px solid #e2e2e2',
                                                    fontSize: '0.9rem'
                                                },
                                                filtering: true
                                            }}
                                        /> </div>)
                                        }
                                </Col>
                            </Row>
                        </div>
                        <div className="verticalSpacer20"></div>
                    </Fragment>
                </section>
            );
        
    }
}