import React, { Component, Fragment } from 'react';
import {Row, Col, Button } from 'react-bootstrap';
import "../assets/css/landholding.css";
import "../assets/css/crops.css";
import UserService from "../services/user.service";
import MaterialTable from 'material-table';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Card from "@material-ui/core/Card";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import tableIcons from './icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faMap,
  faHome,
  faCaretRight,faDownload,faSeedling, faTree
} from "@fortawesome/free-solid-svg-icons";
import AuthService from "../services/auth.service";

import { faHireAHelper } from "@fortawesome/free-brands-svg-icons";
import {TriggerAlert,} from './dryfunctions'
import moment from 'moment';

 
function formatDate(string) {
  const date = moment(string).format(' MMM, YYYY')
  return date

}

function splitMonths(data){

  if(typeof data !== 'string'){
       return data
  }
  
  var expectedMonths = JSON.parse(data)
  return expectedMonths.join(', ')

}


export default class CropListByFarmer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      farmerData: [],
      cropId: 0,
      siteCropId: 0,
      CurrcropName: "",
      showloader: true,
      fpoName: localStorage.getItem("fpoName"),
      isParentLogged: false,
      currentFpo:"",
      season:'',
      isManagerLogged:false
     
      
    };
  }


  

  componentDidMount() {
    
    const user = AuthService.getCurrentUser();
    const fpoId = localStorage.getItem("fpoId")
    let cropId = ""
    let season = ""
    let Year = ""
    let isVerified = ""
    let cropName = ""
    if(!user){
      this.props.history.push('/')
      return
    }
    if(user.is_parent){
      this.setState({isParentLogged: true,currentFpo: this.props.match.params.fpoName})

      
    }

    if(user.is_project_manager){
    this.setState({isManagerLogged:true, currentFpo:this.props.match.params.fpoName})
    }

    if (this.props.match.params) {
      
      let split_url = this.props.match.url.split('/')
      if(split_url.length > 7){
        if(user.is_parent || user.is_project_manager){
          cropId = split_url[3];
          season = split_url[4];
          Year = split_url[5];
          isVerified = split_url[6];
          cropName = split_url.slice(7,).join().replace(',','/')

        }
        else{

          cropId = split_url[2];
          season = split_url[3];
          Year = split_url[4];
          isVerified = split_url[5];
          cropName = split_url.slice(6,).join().replace(',','/')
        }
        
      }
      else{

        cropId = this.props.match.params.cropId;
        season = this.props.match.params.season;
        Year = this.props.match.params.year;
        isVerified = this.props.match.params.isVerified;
        cropName = this.props.match.params.cropName;
      }
      this.setState({
        season,
        cropId
      }, console.log(cropId, 'srksrksrksrksrk'))
      var flag = false;
      
        UserService.getCropsListByFarmer(cropId, season, Year, isVerified, fpoId).then(
          (response) => {
            flag = true;
            if (response.data.success) {
              this.setState({
                farmerData: response.data.data,
                currentCropId: cropId,
                CurrcropName: cropName,
                showloader: false,
              });
            
            }
            
          },
          (error) => {
            flag = true;
            this.setState({
              showloader: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });
            if (error.response) {
              TriggerAlert("Error", error.response.data.message, "error");
            } else {
              TriggerAlert(
                "Error",
                "Server closed unexpectedly, Please try again",
                "error"
              );
            }
          },
          setTimeout(() => {
            if (flag == false) {
              this.setState({
                showloader: false,
              });
              TriggerAlert(
                "Error",
                "Response Timed out, Please try again",
                "info"
              );
              // this.props.history.push("/dashboard");
              this.navigateToPage("dashboard")
            }
          }, 30000)
        );
     
     
    }
  }
  navigateToPage = (pageName) => {
  
    const {fpoName, isParentLogged} = this.state    
    if(isParentLogged){
          if(pageName === "dashboard"){
              this.props.history.push("/fpohomeData");
          }
          else{
              this.props.history.push("/" + pageName + "/"+ fpoName);
          }
    }else{
          this.props.history.push("/" + pageName + "");           
    }
  };

  navigateAgronamy = (data) => {
    const {fpoName, isParentLogged} = this.state
      if(isParentLogged){      
        this.props.history.push(
              "/agronomics/" +
                fpoName +
                "/" +
                data.id +
                "/" +
                data.crop__crop_name.toString() +
                ""
            );
        }else{
          this.props.history.push(
            "/agronomics/" +
              data.id +
              "/" +
              data.crop__crop_name.toString() +
              ""
          );
        }
  }
  handleExportCropData=()=>{
    const cropsdata = this.state.farmerData;
    this.setState({
      showDownloadLoader:true,
      buttonDisableExport:true
  })
  var cropidlist = []
      for(var i=0;i<cropsdata.length;i++){
        cropidlist.push(cropsdata[i]['id'])
      }
    UserService.getCropsListExportData(cropidlist).then(
      (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          var todaydate = moment(new Date()).format('MM-DD-YYYY-hh-mm-a')
          link.setAttribute('download', "Total_Agronomic_Activities_"+todaydate+'.xlsx');
          document.body.appendChild(link);
          link.click();
          this.setState({
              showDownloadLoader:false,
              buttonDisableExport:false
          })           
      });
    }

  render() {
    let { perennialColumns , regularColumns,  farmerData, siteCropId, CurrcropName, showloader, isParentLogged ,currentFpo} = this.state;
     

    //Columns for Perennail Crop data
    //  const perennialColumns = [
    //           {
    //             title: "Farmer Name",
    //             field: "",
    //            export:true,
    //            render: (rowData) => {
    //               return (
    //                 rowData.farmer__first_name +
    //                 " " +
    //                 rowData.farmer__last_name
    //               );
    //             },
              
    //           },
    //           {
    //             title: "Agronomic Activities Captured",
    //             field: "agronomyactivities",
    //             export:true,
    //           },
        
    //           {
    //             title: "First Name",
        
    //             field: "farmer__first_name",
        
    //             hidden: true,
    //             export: true,
    //             searchable: true,
    //           },
              
    //           {
    //             title: "Last Name",
        
    //             field: "farmer__last_name",
        
    //             hidden: true,
    //             export: true,
    //             searchable: true,
    //           },
    //           {
    //             title: "Uprooted On",
    //             field: "uprooted_date",
    //             export:true,
    //             render:(rowData)=>{
    //                return(
    //                 rowData.uprooted_date == 'None'?'NA':rowData.uprooted_date
    //                )
    //             }
                
    //            },
                
    //           {
    //             title: "FR Name",
    //             field: "farmer__farmer_fr__first_name",
    //             export:true,
             
    //           },
    //           {
    //             title: "Location Details",
    //             field: "TotalArea",
              
    //             render: (rowData) => {
    //               return (
    //                 <div className="wrap">
    //                   <Row>
    //                     <Col lg="12" md="12" sm="12">
    //                       <FontAwesomeIcon
    //                         icon={faMapMarkerAlt}
    //                         className="dvaraGreenText"
    //                         title="Village"
    //                       ></FontAwesomeIcon>
    //                       &nbsp;<span title="Village">{rowData.farmer__village}</span>
    //                     </Col>
    //                   </Row>
    //                   <Row>
    //                     <Col lg="12" md="12" sm="12">
    //                       <FontAwesomeIcon
    //                         icon={faMap}
    //                         className="dvaraGreenText"
    //                         title="Site Name"
    //                       ></FontAwesomeIcon>
    //                       &nbsp;<span title="Site Name">{rowData.farmer_site__siteName}</span>
    //                     </Col>
    //                   </Row>
    //                   <Row>
    //                     <Col lg="12" md="12" sm="12">
    //                       <i
    //                         className="iconHolder areaIcon"
    //                         title="Total Area"
    //                       ></i>
    //                       &nbsp;
    //                       <span title="Total Area">
    //                         {rowData.TotalArea} Acres
    //                       </span>
    //                     </Col>
    //                   </Row>
    //                   <Row>
    //                     <Col lg="12" md="12" sm="12">
    //                      <FontAwesomeIcon
    //                         icon={faTree}
    //                         className="dvaraGreenText"
                            
    //                       ></FontAwesomeIcon>
    //                       &nbsp;<span title="Life Span">{rowData.life_span}&nbsp;Years</span>
    //                     </Col>
    //                   </Row>
    //                   <Row>
    //                     <Col lg="12" md="12" sm="12">
    //                      <FontAwesomeIcon
    //                         icon={faSeedling}
    //                         className="dvaraGreenText"
                            
    //                       ></FontAwesomeIcon>
    //                       &nbsp;<span title="Yield Started On">{rowData.yield_start_month}</span>
    //                     </Col>
    //                   </Row>
    //                 </div>
    //               );
    //             },
              
    //           },
    //           // {
    //           //   title: "Village",
        
    //           //   field: "farmer__village",
        
    //           //   hidden: true,
    //           //   export: true,
    //           //   searchable: true,
    //           // },
    //           // {
    //           //   title: "Total Area",
        
    //           //   field: "TotalArea",
        
    //           //   hidden: true,
    //           //   export: true,
    //           //   searchable: true,
    //           // },

    //           // {
    //           //   title: "Site Name",
        
    //           //   field: "farmer_site__siteName",
        
    //           //   hidden: true,
    //           //   export: true,
    //           //   searchable: true,
    //           // },
    //           {
    //             title: "Cropping Info",
                
    //             field: "",
    //             headerStyle: {
                 
    //               textAlign:"center"
    //             },

    //             // field: "duration",
    //             // field: "sowing_date",
    //             // field: "harvest_date",
    //             // field: "month",
    //             render: (rowData) => {

                  
                  
    //               return (
    //                 <div className="">
    //                   <Row
    //                     className="border-bottom-greyDark"
    //                     style={{ padding: "5px 0px 5px 5px" }}
    //                   >
    //                     <Col lg="4" md="4" sm="4" title="Sowing Date">
    //                       {/* <i
    //                         className="CroppingFarmerDetsIcon CroppingFarmerSowingIcon"
    //                         title="Sowing Date"
    //                       /> */}
    //                       &nbsp;
    //                       Sowing:
    //                       <span className="dvaraBrownText">
    //                         {/* {rowData.sowing_date} */}
    //                          &nbsp;{formatDate(rowData.sowing_date)} 
                                       
    //                       </span>
    //                     </Col>
                        
    //                     <Col
    //                       lg="8"
    //                       md="8"
    //                       sm="8"
    //                       title="Harvesting Date"
    //                     >
    //                       {/* <i
    //                         className="CroppingFarmerDetsIcon CroppingFarmerHarvestIcon"
    //                         title="Harvesting Date"
    //                       /> */}
    //                       First Harvesting:
    //                       &nbsp;
                          
    //                       <span className="dvaraBrownText">
    //                         {/* {rowData.harvest_date} */}
    //                         {/* {formatDate(rowData.first_harvest)} */}
    //                         {
    //                           this.state.season == 'Perennial'? formatDate(rowData.first_harvest) : formatDate(rowData.harvest_date)

    //                         }
                            
    //                       </span>
    //                     </Col>
    //                     {/* <Col lg="4" md="4" sm="4">
    //                       Duration:&nbsp;
                          
    //                       <span className="dvaraBrownText">
    //                         {rowData.duration}&nbsp;Days
    //                       </span>
    //                     </Col> */}
                        
    //                     {/* <Col lg="4" md="4" sm="4">
    //                       Month(s):
    //                       <br />
    //                       <span className="dvaraBrownText">
    //                         {rowData.month}
    //                       </span>
    //                     </Col> */}
    //                   </Row>
    //                   <Row
    //                     className=""
    //                     style={{ padding: "5px 0px 5px 5px" }}
    //                   >
    //                     {/* <Col
    //                       lg="4"
    //                       md="8"
    //                       sm="8"
    //                       title="Seed Variety"
    //                     >
    //                       <i
    //                         className="CroppingFarmerDetsIcon CroppingFarmerSeedVarietyIcon"
    //                         title="Seed Variety"
    //                       />
    //                       &nbsp;
    //                       <span className="dvaraBrownText">
    //                         {rowData.variety}
    //                       </span>
    //                     </Col> */}
                        
    //                     <Col lg="4" md="4" sm="4">
    //                       Total Crop Yield(Qtl.):&nbsp;
                          
    //                       <span className="dvaraBrownText">
    //                         {rowData.crop_yield !== null && rowData.crop_yield !== ''?rowData.crop_yield :'0' }
    //                       </span>
    //                     </Col>
    //                     <Col
    //                       lg="8"
    //                       md="8"
    //                       sm="8"
    //                       title="Harvesting Date"
    //                     >
    //                       {/* <i
    //                         className="CroppingFarmerDetsIcon CroppingFarmerHarvestIcon"
    //                         title="Harvesting Date"
    //                       /> */}
    //                       Expected Months:
    //                       &nbsp;
                        
    //                       <span className="dvaraBrownText">
    //                         {/* {rowData.harvest_date} */}
    //                         {this.state.season == 'Perennial'?splitMonths(rowData.harvest_months):'NA'}
    //                       </span>
    //                     </Col>
                        
    //                   </Row>
                      
    //                 </div>
    //               );
    //             },
    //             // cellStyle: {
    //             //   width: "20%",
    //             //   backgroundColor: "red"
    //             //   // padding: "0",
    //             // },
    //           },
    //           {
    //             title: "Total Crop Yield",
        
    //             field: "crop_yield",
        
    //             hidden: true,
    //             export: true,
    //             searchable: true,
    //           },
    //           {
    //             title: "Duration (days)",
        
    //             field: "duration",
        
    //             hidden: true,
    //             export: true,
    //             searchable: true,
    //           },
    //           ///
    //           {
    //             title: "Months",
        
    //             field: "month",
        
    //             hidden: true,
    //             export: true,
    //             searchable: true,
    //           },
    //           {
    //             title: "Sowing Date",
        
    //             field: "sowing_date",
        
    //             hidden: true,
    //             export: true,
    //             searchable: true,
    //           },
    //           {
    //             title: "Harvest Date",
        
    //             field: "harvest_date",
        
    //             hidden: true,
    //             export: true,
    //             searchable: true,
    //           },
    //         ]
            
   //Columns for Kharif, Rabi, Zaid.. crops data
    //  const regularColumns = [
    //         {
    //           title: "Farmer Name",
    //           field: "",
    //           cellStyle: {
    //             width: "10%",
    //         },
            
    //           export:true,
    //           // field: "farmer__last_name",
    //           // style: {width: "0px"},
    //           render: (rowData) => {
    //             return (
    //               rowData.farmer__first_name +
    //               " " +
    //               rowData.farmer__last_name
    //             );
    //           },
            
    //         },
    //         {
    //           title: "Agronomic Activities Captured",
    //           field: "agronomyactivities",
    //           cellStyle: {
    //             width: "10%",
    //             textAlign:"center"
    //         },
    //           export:true,
    //         },
      
    //         {
    //           title: "First Name",
      
    //           field: "farmer__first_name",
      
    //           hidden: true,
    //           export: true,
    //           searchable: true,
    //         },
            
    //         {
    //           title: "Last Name",
      
    //           field: "farmer__last_name",
      
    //           hidden: true,
    //           export: true,
    //           searchable: true,
    //         },              
    //         {
    //           title: "FR Name",
    //           field: "farmer__farmer_fr__first_name",
    //           // style: {width: "0px"},
    //           cellStyle: {
    //             width: "10%",
    //         },
    //           export:true,
           
    //         },
    //         {
    //           title: "Location Details",
    //           cellStyle: {
    //             width: "10%",
    //         },
    //           field: "",
            
    //           render: (rowData) => {
    //             return (
    //               <div className="wrap">
    //                 <Row>
    //                   <Col lg="12" md="12" sm="12">
    //                     <FontAwesomeIcon
    //                       icon={faMapMarkerAlt}
    //                       className="dvaraGreenText"
    //                       title="Village"
    //                     ></FontAwesomeIcon>
    //                     &nbsp;{rowData.farmer__village}
    //                   </Col>
    //                 </Row>
    //                 <Row>
    //                   <Col lg="12" md="12" sm="12">
    //                     <FontAwesomeIcon
    //                       icon={faMap}
    //                       className="dvaraGreenText"
    //                       title="Site Name"
    //                     ></FontAwesomeIcon>
    //                     &nbsp;{rowData.farmer_site__siteName}
    //                   </Col>
    //                 </Row>
    //                 <Row>
    //                   <Col lg="12" md="12" sm="12">
    //                     <i
    //                       className="iconHolder areaIcon"
    //                       title="Total Area"
    //                     ></i>
    //                     &nbsp;
    //                     <span title="Total Area">
    //                       {rowData.TotalArea} Acres
    //                     </span>
    //                   </Col>
    //                 </Row>
    //               </div>
    //             );
    //           },
            
    //         },
    //         {
    //           title: "Village",
      
    //           field: "farmer__village",
      
    //           hidden: true,
    //           export: true,
    //           searchable: true,
    //         },
    //         {
    //           title: "Total Area",
      
    //           field: "TotalArea",
      
    //           hidden: true,
    //           export: true,
    //           searchable: true,
    //         },

    //         {
    //           title: "Site Name",
      
    //           field: "farmer_site__siteName",
      
    //           hidden: true,
    //           export: true,
    //           searchable: true,
    //         },
    //         {
    //           title: "Cropping Info",
    //           // style: {width: "0px"},
    //           field: "",
    //           cellStyle: {
    //           width: "25%",
    //         },
    //            headerStyle: {
               
    //             textAlign:"center"
    //           },

    //           // field: "duration",
    //           // field: "sowing_date",
    //           // field: "harvest_date",
    //           // field: "month",
    //           render: (rowData) => {

                
                
    //             return (
    //               <div className="">
    //                 <Row
    //                   className="border-bottom-greyDark"
    //                   style={{ padding: "5px 0px 5px 5px" }}
    //                 >
                      
    //                   <Col lg="6" md="6" sm="6"   >
    //                     Total Crop Yield(Qtl.):&nbsp;
                        
    //                     <span className="dvaraBrownText">
    //                       {rowData.crop_yield !== null && rowData.crop_yield !== ''?rowData.crop_yield :'0' }
    //                     </span>
    //                   </Col>              
      
    //                   <Col lg="6" md="6" sm="6"   >
    //                     Duration:&nbsp;
                        
    //                     <span className="dvaraBrownText">
    //                       {rowData.duration}
    //                     </span>
    //                   </Col>
    //                 </Row>
    //                 <Row className=" "
    //                   style={{ padding: "5px 0px 5px 5px" }}>
    //                   <Col lg="6" md="6" sm="6" title="Sowing Date">
    //                     <i
    //                       className="CroppingFarmerDetsIcon CroppingFarmerSowingIcon"
    //                       title="Sowing Date"
    //                     />
    //                     &nbsp;
    //                     {/* Sowing: */}
    //                     <span className="dvaraBrownText">
    //                       {/* {rowData.sowing_date} */}
    //                        &nbsp;{formatDate(rowData.sowing_date)} 
                                     
    //                     </span>
    //                   </Col>
    //                   <Col
    //                     lg="6"
    //                     md="6"
    //                     sm="6"
    //                     title="Harvesting Date"
    //                   >
    //                     <i
    //                       className="CroppingFarmerDetsIcon CroppingFarmerHarvestIcon"
    //                       title="Harvesting Date"
    //                     />
    //                      {/* Harvesting: */}
    //                     &nbsp;
                        
    //                     <span className="dvaraBrownText">
    //                       {/* {rowData.harvest_date} */}
    //                       {formatDate(rowData.harvest_date)}
    //                       {/* {
    //                         this.state.season == 'Perennial'? formatDate(rowData.first_harvest) : formatDate(rowData.harvest_date)

    //                       } */}
                          
    //                     </span>
    //                   </Col>
    //                 </Row>
                    
    //               </div>
    //             );
    //           },
    //           // cellStyle: {
    //           //   width: "20%",
    //           //   backgroundColor: "red"
    //           //   // padding: "0",
    //           // },
    //         },
    //         {
    //           title: "Total Crop Yield",
      
    //           field: "crop_yield",
      
    //           hidden: true,
    //           export: true,
    //           searchable: true,
    //         },
    //         {
    //           title: "Duration (days)",
      
    //           field: "duration",
      
    //           hidden: true,
    //           export: true,
    //           searchable: true,
    //         },
    //         ///
    //         {
    //           title: "Months",
      
    //           field: "month",
      
    //           hidden: true,
    //           export: true,
    //           searchable: true,
    //         },
    //         {
    //           title: "Sowing Date",
      
    //           field: "sowing_date",
      
    //           hidden: true,
    //           export: true,
    //           searchable: true,
    //         },
    //         {
    //           title: "Harvest Date",
      
    //           field: "harvest_date",
      
    //           hidden: true,
    //           export: true,
    //           searchable: true,
    //         },
    //       ]

    if (farmerData) {
      return (
        <section className="mainWebContentSection">
          <Fragment>
            <div className="breadcrumb pageBreadCrumbHolder">
              <a
                href="#"
                className="breadcrumb-item breadcrumbs__crumb pageBreadCrumbItem"
                onClick={() => this.navigateToPage("dashboard")}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
                />
                &nbsp;Dashboard
              </a>
              <FontAwesomeIcon
                icon={faCaretRight}
                className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
              />
              <a
                href="#"
                className="breadcrumb-item breadcrumbs__crumb breadcrumbs__crumb pageBreadCrumbItem"
                onClick={() => this.navigateToPage("crops")}
              >
                Crops List
              </a>
             
            </div>
            {this.state.isParentLogged  || this.state.isManagerLogged? 
                   <div style={{ marginLeft: "30px", color: 'rgba(114, 49, 12, 1)'  }} >
                   <h5 style={{marginLeft:"28px"}}> FPO: {currentFpo} </h5>
                  </div>
                   : ""}
           <Row>
            <Col lg="12" md="12" sm="12" className="noPadding">
                                  <Button onClick={this.handleExportCropData}
                                   className="defaultButtonElem"
                                   style={{position:'relative',float:"right",right:'41px', top:'128px'}}
                                   variant="primary"
                                   size="sm">
                             <FontAwesomeIcon
                              icon={faDownload}
                              className="dvaraBrownText"
                              ></FontAwesomeIcon>
                            &nbsp;Export Data
                            {this.state.showDownloadLoader ? (
                          <div className="formDistLoadSpinnerWrap">
                          <span className="spinner-border spinner-border-sm"></span>
                         </div>
                         ) : (
                        <div className="formDistLoadSpinnerWrap"></div>
                        )}
                          </Button>
                </Col>
            </Row>
            <div className="wrap village-box-details" style={{width:"83%"}}>
                    <Row>
                        <Col md="4">Crop Name : <strong className="colorCateg">{this.props.match.params.cropName}</strong></Col>
                        <Col md="4">Year : <strong className="colorCateg">{this.props.match.params.year}</strong></Col>
                        <Col md="4">Season : <strong className="colorCateg">{this.props.match.params.season}</strong></Col>

                    </Row>
                
                     <br/>
                
                    <Row>
                        <Col md="4">Verification Status : <strong className="colorCateg">{this.props.match.params.isVerified=="2"?"Verified":"Unverified"}</strong></Col>
                      

                    </Row>
                    
                    </div>
            <div className="landholdingHeader wrap">
              <Row>
                <Col lg="12" md="12" sm="12">
                  <div className="PageHeading padding15" style={{marginLeft:"25px"}}>
                    <h4 className="farmerListHeading dvaraBrownText">
                      Crop Farmers
                    </h4>
                    <br />
                    <h6 className="HeaderListSubText dvaraBrownText">
                      {" Crop Name : " + CurrcropName}
                    </h6>
                  </div>
                  {showloader ? (
                    <div className="mainCropsFarmerLoaderWrap">
                      <span className="spinner-border spinner-border-lg mainCropsFarmerLoader"></span>
                    </div>
                  ) : (
                    <MaterialTable
                      icons={tableIcons}
                      title=""
                      style={{ width: "100%", marginLeft:'30px' }}
                      // columns={ this.state.season == 'Perennial'?perennialColumns:regularColumns}
                      columns={this.state.season == 'Perennial'?
                       perennialColumns = [
                        {
                          title: "Farmer Name",
                          field: "",
                          width:'5%',
                          export:true,
                         render: (rowData) => {
                            return (
                              rowData.farmer__first_name +
                              " " +
                              rowData.farmer__last_name
                            );
                          },
                        
                        },
                        {
                          title: "Agronomic Activities Captured",
                          field: "agronomyactivities",
                          export:true,
                          width:"5%"
                        },
                  
                        {
                          title: "First Name",
                  
                          field: "farmer__first_name",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                        
                        {
                          title: "Last Name",
                  
                          field: "farmer__last_name",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                       
                          
                        {
                          title: "FR Name",
                          field: "farmer__farmer_fr__first_name",
                          export:true,
                          width:'10%'
                       
                        },
                        {
                          title: "Uprooted On",
                          field: "uprooted_date",
                          export:true,
                          width:'10%',
                          render:(rowData)=>{
                             return(
                              rowData.uprooted_date == 'None'?'NA':rowData.uprooted_date
                             )
                          }
                          
                         },
                        {
                          title: "Location Details",
                          field: "TotalArea",
                          width:'10%',
                        
                          render: (rowData) => {
                            return (
                              <div className="wrap">
                                <Row>
                                  <Col lg="12" md="12" sm="12">
                                    <FontAwesomeIcon
                                      icon={faMapMarkerAlt}
                                      className="dvaraGreenText"
                                      title="Village"
                                    ></FontAwesomeIcon>
                                    &nbsp;<span title="Village">{rowData.farmer__village}</span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12" md="12" sm="12">
                                    <FontAwesomeIcon
                                      icon={faMap}
                                      className="dvaraGreenText"
                                      title="Site Name"
                                    ></FontAwesomeIcon>
                                    &nbsp;<span title="Site Name">{rowData.farmer_site__siteName}</span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12" md="12" sm="12">
                                    <i
                                      className="iconHolder areaIcon"
                                      title="Total Area"
                                    ></i>
                                    &nbsp;
                                    <span title="Total Area">
                                      {rowData.TotalArea} Acres
                                    </span>
                                  </Col>
                                </Row>
                                {/* <Row>
                                  <Col lg="12" md="12" sm="12">
                                   <FontAwesomeIcon
                                      icon={faTree}
                                      className="dvaraGreenText"
                                      title="Life Span"
                                    ></FontAwesomeIcon>
                                    &nbsp;<span title="Life Span">{rowData.life_span}&nbsp;Years</span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12" md="12" sm="12">
                                   <FontAwesomeIcon
                                      icon={faSeedling}
                                      className="dvaraGreenText"
                                      title="Yield Started On"
                                    ></FontAwesomeIcon>
                                    &nbsp;<span title="Yield Started On">{rowData.yield_start_month}</span>
                                  </Col>
                                </Row> */}
                              </div>
                            );
                          },
                        
                        },
                        // {
                        //   title: "Village",
                  
                        //   field: "farmer__village",
                  
                        //   hidden: true,
                        //   export: true,
                        //   searchable: true,
                        // },
                        // {
                        //   title: "Total Area",
                  
                        //   field: "TotalArea",
                  
                        //   hidden: true,
                        //   export: true,
                        //   searchable: true,
                        // },
          
                        // {
                        //   title: "Site Name",
                  
                        //   field: "farmer_site__siteName",
                  
                        //   hidden: true,
                        //   export: true,
                        //   searchable: true,
                        // },
                        {
                          title: "Cropping Info",
                          width:'60%',
                          field: "",
                          headerStyle: {
                           
                            textAlign:"center"
                          },
          
                          // field: "duration",
                          // field: "sowing_date",
                          // field: "harvest_date",
                          // field: "month",
                          render: (rowData) => {
          
                            
                            
                            return (
                              <div className="">
                                <Row
                                  className=""
                                  style={{ padding: "5px 0px 5px 5px" }}
                                >
                                  <Col lg="6" md="6" sm="6" title="Sowing Date">
                                    {/* <i
                                      className="CroppingFarmerDetsIcon CroppingFarmerSowingIcon"
                                      title="Sowing Date"
                                    /> */}
                                    
                                    Sowing:
                                    <span className="dvaraBrownText">
                                      {/* {rowData.sowing_date} */}
                                       &nbsp;{formatDate(rowData.sowing_date)} 
                                                 
                                    </span>
                                  </Col>
                                  
                                  <Col
                                    lg="6"
                                    md="6"
                                    sm="6"
                                    title="Harvesting Date"
                                  >
                                    {/* <i
                                      className="CroppingFarmerDetsIcon CroppingFarmerHarvestIcon"
                                      title="Harvesting Date"
                                    /> */}
                                    First Harvesting:
                                    &nbsp;
                                    
                                    <span className="dvaraBrownText">
                                      {/* {rowData.harvest_date} */}
                                      {/* {formatDate(rowData.first_harvest)} */}
                                      {
                                        this.state.season == 'Perennial'? formatDate(rowData.first_harvest) : formatDate(rowData.harvest_date)
          
                                      }
                                      
                                    </span>
                                  </Col>
                                  {/* <Col lg="4" md="4" sm="4">
                                    Duration:&nbsp;
                                    
                                    <span className="dvaraBrownText">
                                      {rowData.duration}&nbsp;Days
                                    </span>
                                  </Col> */}
                                  
                                  {/* <Col lg="4" md="4" sm="4">
                                    Month(s):
                                    <br />
                                    <span className="dvaraBrownText">
                                      {rowData.month}
                                    </span>
                                  </Col> */}
                                </Row>
                                <Row
                                  className=""
                                  style={{ padding: "5px 0px 5px 5px" }}
                                >
                                  {/* <Col
                                    lg="4"
                                    md="8"
                                    sm="8"
                                    title="Seed Variety"
                                  >
                                    <i
                                      className="CroppingFarmerDetsIcon CroppingFarmerSeedVarietyIcon"
                                      title="Seed Variety"
                                    />
                                    &nbsp;
                                    <span className="dvaraBrownText">
                                      {rowData.variety}
                                    </span>
                                  </Col> */}
                                  
                                  {/* <Col lg="6" md="6" sm="6">
                                    Total Crop Yield(Qtl.):&nbsp;
                                    
                                    <span className="dvaraBrownText">
                                      {rowData.crop_yield !== null && rowData.crop_yield !== ''?rowData.crop_yield :'0' }
                                    </span>
                                  </Col> */}
                                   
                                  <Col lg="6" md="6" sm="6">
                                    Life Span:&nbsp;
                                    
                                    <span className="dvaraBrownText">
                                      {rowData.life_span }&nbsp;Years
                                    </span>
                                  </Col>
                                  
                                  <Col
                                    lg="6"
                                    md="6"
                                    sm="6"
                                    title="Harvesting Date"
                                  >
                                    {/* <i
                                      className="CroppingFarmerDetsIcon CroppingFarmerHarvestIcon"
                                      title="Harvesting Date"
                                    /> */}
                                    Expected Months:
                                    &nbsp;
                                  
                                    <span className="dvaraBrownText">
                                      {/* {rowData.harvest_date} */}
                                      {this.state.season == 'Perennial'?splitMonths(rowData.harvest_months):'NA'}
                                    </span>
                                  </Col>
                                </Row>
                                <Row
                                  className=""
                                  style={{ padding: "5px 0px 5px 5px" }}
                                >
                                  
                                  
                                  {/* <Col lg="6" md="6" sm="6">
                                    Total Crop Yield(Qtl.):&nbsp;
                                    
                                    <span className="dvaraBrownText">
                                      {rowData.crop_yield !== null && rowData.crop_yield !== ''?rowData.crop_yield :'0' }
                                    </span>
                                  </Col> */}
                                    <Col
                                    lg="6"
                                    md="6"
                                    sm="6"
                                    title="Harvesting Date"
                                  >
                                    {/* <i
                                      className="CroppingFarmerDetsIcon CroppingFarmerHarvestIcon"
                                      title="Harvesting Date"
                                    /> */}
                                    Yield Started On:
                                    &nbsp;
                                  
                                    <span className="dvaraBrownText">
                                      {/* {rowData.harvest_date} */}
                                      {rowData.yield_start_month}
                                    </span>
                                  </Col>
                                  <Col lg="6" md="6" sm="6">
                                    Total Crop Yield(Qtl.):&nbsp;
                                    
                                    <span className="dvaraBrownText">
                                      {rowData.crop_yield !== null && rowData.crop_yield !== ''?rowData.crop_yield :'0' }
                                    </span>
                                  </Col>
                                  
                                
                                </Row>
                                
                              </div>
                            );
                          },
                          // cellStyle: {
                          //   width: "20%",
                          //   backgroundColor: "red"
                          //   // padding: "0",
                          // },
                        },
                        {
                          title: "Total Crop Yield",
                  
                          field: "crop_yield",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                        {
                          title: "Duration (days)",
                  
                          field: "duration",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                        ///
                        {
                          title: "Months",
                  
                          field: "month",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                        {
                          title: "Sowing Date",
                  
                          field: "sowing_date",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                        {
                          title: "Harvest Date",
                  
                          field: "harvest_date",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                      ]:regularColumns = [
                        {
                          title: "Farmer Name",
                          field: "",
                          width:'10%',
                          export:true,
                               render: (rowData) => {
                            return (
                              rowData.farmer__first_name +
                              " " +
                              rowData.farmer__last_name
                            );
                          },
                        
                        },
                        {
                          title: "Agronomic Activities Captured",
                          field: "agronomyactivities",
                          export:true,
                          width:'10%',
                        },
                  
                        {
                          title: "First Name",
                  
                          field: "farmer__first_name",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                        
                        {
                          title: "Last Name",
                  
                          field: "farmer__last_name",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },              
                        {
                          title: "FR Name",
                          field: "farmer__farmer_fr__first_name",
                          export:true,
                          width:'10%',
                       
                        },
                        {
                          title: "Location Details",
                          field: "",
                          width:'10%',
                          render: (rowData) => {
                            return (
                              <div className="wrap">
                                <Row>
                                  <Col lg="12" md="12" sm="12">
                                    <FontAwesomeIcon
                                      icon={faMapMarkerAlt}
                                      className="dvaraGreenText"
                                      title="Village"
                                    ></FontAwesomeIcon>
                                    &nbsp;<span title="Village">{rowData.farmer__village}</span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12" md="12" sm="12">
                                    <FontAwesomeIcon
                                      icon={faMap}
                                      className="dvaraGreenText"
                                      title="Site Name"
                                    ></FontAwesomeIcon>
                                    &nbsp;<span title="Site Name">{rowData.farmer_site__siteName}
                                  </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12" md="12" sm="12">
                                    <i
                                      className="iconHolder areaIcon"
                                      title="Total Area"
                                    ></i>
                                    &nbsp;
                                    <span title="Total Area">
                                      {rowData.TotalArea} Acres
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            );
                          },
                        
                        },
                        {
                          title: "Village",
                  
                          field: "farmer__village",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                        {
                          title: "Total Area",
                  
                          field: "TotalArea",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
            
                        {
                          title: "Site Name",
                  
                          field: "farmer_site__siteName",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                        {
                          title: "Cropping Info",
                          field: "",
                          width:'40%',
                           headerStyle: {
                           
                            textAlign:"center"
                          },
            
                          // field: "duration",
                          // field: "sowing_date",
                          // field: "harvest_date",
                          // field: "month",
                          render: (rowData) => {
            
                            
                            
                            return (
                              <div className="">
                                
                                <Row className="border-bottom-greyDark "
                                  style={{ padding: "5px 0px 5px 5px" }}>
                                  <Col lg="6" md="6" sm="6" title="Sowing Date">
                                    <i
                                      className="CroppingFarmerDetsIcon CroppingFarmerSowingIcon"
                                      title="Sowing Date"
                                    />
                                    &nbsp;
                                    {/* Sowing: */}
                                    <span className="dvaraBrownText">
                                      {/* {rowData.sowing_date} */}
                                       &nbsp;{formatDate(rowData.sowing_date)} 
                                                 
                                    </span>
                                  </Col>
                                  <Col
                                    lg="6"
                                    md="6"
                                    sm="6"
                                    title="Harvesting Date"
                                  >
                                    <i
                                      className="CroppingFarmerDetsIcon CroppingFarmerHarvestIcon"
                                      title="Harvesting Date"
                                    />
                                     {/* Harvesting: */}
                                    &nbsp;
                                    
                                    <span className="dvaraBrownText">
                                      {/* {rowData.harvest_date} */}
                                      {formatDate(rowData.harvest_date)}
                                      {/* {
                                        this.state.season == 'Perennial'? formatDate(rowData.first_harvest) : formatDate(rowData.harvest_date)
            
                                      } */}
                                      
                                    </span>
                                  </Col>
                                </Row>
                                <Row
                                  className=""
                                  style={{ padding: "5px 0px 5px 5px" }}
                                >
                                  
                                  <Col lg="6" md="6" sm="6"   >
                                    Total Crop Yield(Qtl.):&nbsp;
                                    
                                    <span className="dvaraBrownText">
                                      {rowData.crop_yield !== null && rowData.crop_yield !== ''?rowData.crop_yield :'0' }
                                    </span>
                                  </Col>              
                  
                                  <Col lg="6" md="6" sm="6"   >
                                    Duration:&nbsp;
                                    
                                    <span className="dvaraBrownText">
                                      {rowData.duration}
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            );
                          },
                          // cellStyle: {
                          //   width: "20%",
                          //   backgroundColor: "red"
                          //   // padding: "0",
                          // },
                        },
                        {
                          title: "Total Crop Yield",
                  
                          field: "crop_yield",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                        {
                          title: "Duration (days)",
                  
                          field: "duration",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                        ///
                        {
                          title: "Months",
                  
                          field: "month",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                        {
                          title: "Sowing Date",
                  
                          field: "sowing_date",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                        {
                          title: "Harvest Date",
                  
                          field: "harvest_date",
                  
                          hidden: true,
                          export: true,
                          searchable: true,
                        },
                      ]}
                      data={farmerData}
                      options={{
                        doubleHorizontalScroll: true,
                        // tableLayout:'fixed',
                        pageSize: 10,
                        pageSizeOptions: [
                          10,
                          20,
                          50,
                          100,
                          { value: farmerData.length, label: "All" },
                        ],
                        
                        headerStyle: {
                          backgroundColor: "#A3C614",
                          color: "#fff",
                          fontSize: "1.2rem",
                          fontFamily: "barlow_reg",
                          fontWeight: "bold",
                        
                        },
                        rowStyle: {
                          backgroundColor: "#f1f1f1",
                          borderBottom: "2px solid #e2e2e2",
                          fontSize: "0.9rem",
                        
                        },
                        filtering: false,
                        exportButton: true,
                      }}
                      actions={[
                        {
                          icon: "View",
                          tooltip: "Go to Agronomics Page",
                          onClick: (event, rowData) => {this.navigateAgronamy(rowData)},
                            // this.props.history.push(
                            //   "/agronomics/" +
                              
                            //     rowData.id +
                            //     "/" +
                            //     rowData.crop__crop_name.toString() +
                            //     ""
                            // );
                          // },
                       
                        },

                      ]}
                      components={{
                        Action: (props) => (
                          <div
                            onClick={(event) =>
                              props.action.onClick(event, props.data)
                            }
                            className="actionButtonCropFarmerDets"
                            title="View Agronomics"
                          >
                            <i className="viewAgroButton"></i>
                          </div>
                        ),
                      }}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Fragment>
        </section>
      );
    } else {
      return (
        <div>
          <p>No Data Avaialable</p>
        </div>
      );
    }
  }
}
