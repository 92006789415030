import React, { Component,  Fragment, useState } from "react";
import { Container, Card, Alert, Row, Col, ProgressBar, ProgressBarProps, ToggleButtonGroup, ToggleButton, Form, Breadcrumb, Button } from "react-bootstrap";
import { Redirect, Route,Link } from 'react-router-dom';
import UserService from "../services/user.service";
import "../assets/css/crops.css";
import  MaterialTable  from "material-table";
import tableIcons from './icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import crop_growing from "../assets/img/crop_growing.gif";

import { faHtml5, faHome,faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import {TriggerAlert,} from './dryfunctions'
import AuthService from "../services/auth.service";


      // have used class Component and inside that have decrlared all the needed variable in this.state.
export default class CropsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cropsdata: [],
      dateRanges: [],
      showloader: true,
      isParentLogged: false,
      isManagerLogged:false,
      fpoName: localStorage.getItem('fpoName'),
      currentFpo:"",
      currentYear:"",
      CurrentSeason:"",
      CurrentMonth:"",
      selectStatus:'',
      selectSeason:'',
      selectYear:'',
      startYear:'',
      endYear:''
    };
  }

  navback = ()=>{
    const {  isParentLogged, isManagerLogged } = this.state

    if (isParentLogged || isManagerLogged) {
        this.props.history.push("/fpocrops/" );
    }
}
  // on Click on crop Name inside Material Table in the first and last row this navigateToPage function will be Called
  navigateToPage = ( season, year,  isVerified, cropId,  area, 
      cropName = null, compType ) => {
    // as we r using the same navigateToPage function multiple times so have applied condition based on that it will take to respective page.
    const { fpoName, isParentLogged , isManagerLogged} = this.state
    if (compType === "cropDetails") {
      if(isParentLogged || isManagerLogged){
        this.props.history.push("/cropsFarmersList/" + fpoName + "/" + cropId + "/" + season + "/" +
            year + "/" + isVerified + "/" + cropName);
      }else{
        this.props.history.push("/cropsFarmersList/" + cropId + "/" + season + "/" + year + "/" +
            isVerified + "/" + cropName );
      }
    }else {
      if(isParentLogged || isManagerLogged){
        this.props.history.push("/inputcomponent/" + fpoName +  "/" + season + "/" + year + "/" + isVerified + 
        "/" + area + "/" + cropId);
      }else{
      this.props.history.push("/inputcomponent/" + season + "/" + year + "/" + isVerified + "/" + area + 
        "/" + cropId);
      }
    }
  };
  // this function is called in Breadcrumb
  navigateMainBoard = () => {
    const {isParentLogged, isManagerLogged} = this.state
    if(isParentLogged ||isManagerLogged ){
      this.props.history.push("/fpohomeData");
    }
    else{
      this.props.history.push("/dashboard");
    }
  }
  
  cropListFilter=()=>{
    const {selectSeason, startYear, endYear, selectStatus } = this.state
    var flag = false;
    const user = AuthService.getCurrentUser();
    const fpoId = localStorage.getItem("fpoId")
    const currentYear = user.current_year;
    const currentMonth = user.month;
    const currentSeason = user.current_season;

    

    let url = ''
    if(this.state.startYear>this.state.endYear ) {
     TriggerAlert('Error', 'End Year should not be Less than Start year', 'error')

     return false;

    }
    if(selectSeason != ''){
      url = `${url}/${selectSeason}`
    }
    if(startYear != ''){
      url = `${url}/${startYear}`
    }
    if(endYear != ''){
      url = `${url}/${endYear}`
    }
    if(selectStatus != ''){
      url = `${url}/${selectStatus}`;
    }

    this.setState({
      showloader:true
    })

    UserService.getCropsList(fpoId, url).then(
      (response) => {
        flag = true;
        
        this.setState({
          cropsdata: response.data.crops == null ? [] : response.data.crops,
          showloader: false,
        });
          },
      (error) => {
        flag = true;
        this.setState({
          showloader: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
        if (error.response) {
          TriggerAlert("Error", error.response.data.message, "error");
        } else {
          TriggerAlert(
            "Error",
            "Server closed unexpectedly, Please try again",
            "error"
          );
        }
      },
      // we r using setTimeOut function to display error message for a period of Time.
      setTimeout(() => {
        if (flag == false) {
          this.setState({
            showloader: false,
          });
          TriggerAlert("Error", "Response Timed out, Please try again", "info");
          // this.props.history.push("/dashboard");
          this.navigateMainBoard()
        }
      }, 30000)
    );
    
    
  }

  // inside componentDid we are calling the api and taking data from api,storing that in a state variable .
  //  Here if response is not true then api will through an error message.
  componentDidMount() {
    var flag = false;
    const user = AuthService.getCurrentUser();
    const fpoId = localStorage.getItem("fpoId")
    let currentYear = user.current_year;
    const currentMonth = user.month;
    const currentSeason = user.current_season;
    if(!user){
      this.props.history.push('/')
      return
    }
    if(user.is_parent){
      this.setState({isParentLogged: true,currentFpo: this.props.match.params.fpoName})
    }
    if(user.is_project_manager){
      this.setState({isManagerLogged: true,currentFpo: this.props.match.params.fpoName})
    }
    if (user) {
      currentYear = String(currentYear).split('-')[0]
      this.setState({
        currentUser: user,
        CurrentYear:currentYear,
        CurrentSeason:currentSeason,
        CurrentMonth:currentMonth,
        selectSeason:currentSeason,
        startYear:currentYear,
        endYear:currentYear,
        selectStatus:-1

      });
    }

    let url = ''
    
    const {selectSeason, startYear, endYear } = this.state
    
    if(selectSeason != ''){
      url = `${url}/${selectSeason}`
    }
    if(startYear != ''){
      url = `${url}/${startYear}`
    }
    if(endYear != ''){
      url = `${url}/${endYear}`
    }
    





    if(url == ''){
      url = `/${currentSeason}/${currentYear}/${currentYear}/${-1}`
    }

    UserService.getCropsList(fpoId, url).then(
      (response) => {
        flag = true;
         this.setState({
          cropsdata: response.data.crops == null ? [] : response.data.crops,
          // dateRanges: dateDict,
          showloader: false,

        }, ()=>{
        });
        },
      (error) => {
        flag = true;
        this.setState({
          showloader: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
        if (error.response) {
          TriggerAlert("Error", error.response.data.message, "error");
        } else {
          TriggerAlert(
            "Error",
            "Server closed unexpectedly, Please try again",
            "error"
          );
        }
      },
      // we r using setTimeOut function to display error message for a period of Time.
      setTimeout(() => {
        if (flag == false) {
          this.setState({
            showloader: false,
          });
          TriggerAlert("Error", "Response Timed out, Please try again", "info");
          // this.props.history.push("/dashboard");
          this.navigateMainBoard()
        }
      }, 30000)
    );

   if(user.is_parent){
    return  UserService.getYearRangesFpo('crop_wise', fpoId).then(
      (response) => {
          flag = true;
          // var dateDict = {};
          // response.data.date_range.map((date) => {
          //     dateDict[date] = date;
          // });
          this.setState({
              
              dateRanges: response.data.sowing_year_range == null ? [] : response.data.sowing_year_range,
              showloader: false,
          });
      },
      (error) => {
          flag = true;
          this.setState({
              showloader: false,
              content:
                  (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                  error.message ||
                  error.toString(),
          });
          if (error.response) {
              TriggerAlert("Error", error.response.data.message, "error");
          } else {
              TriggerAlert(
                  "Error",
                  "Server closed unexpectedly, Please try again",
                  "error"
              );
          }
      },
      setTimeout(() => {
          if (flag == false) {
              this.setState({
                  showloader: false,
              });
              TriggerAlert("Error", "Response Timed out, Please try again", "info");
              this.navigateMainBoard()
          }
      }, 30000)
  );
   }else if (user.is_project_manager){
   return  UserService.getYearRangesFpoManager('crop_wise', fpoId).then(
    (response) => {
        flag = true;
        // var dateDict = {};
        // response.data.date_range.map((date) => {
        //     dateDict[date] = date;
        // });
        this.setState({
            
            dateRanges: response.data.sowing_year_range == null ? [] : response.data.sowing_year_range,
            showloader: false,
        });
    },
    (error) => {
        flag = true;
        this.setState({
            showloader: false,
            content:
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString(),
        });
        if (error.response) {
            TriggerAlert("Error", error.response.data.message, "error");
        } else {
            TriggerAlert(
                "Error",
                "Server closed unexpectedly, Please try again",
                "error"
            );
        }
    },
    setTimeout(() => {
        if (flag == false) {
            this.setState({
                showloader: false,
            });
            TriggerAlert("Error", "Response Timed out, Please try again", "info");
            this.navigateMainBoard()
        }
    }, 30000)
);
   }
   else{
    return  UserService.getYearRanges('crop_wise').then(
      (response) => {
          flag = true;
          // var dateDict = {};
          // response.data.date_range.map((date) => {
          //     dateDict[date] = date;
          // });
          this.setState({
              
              dateRanges: response.data.sowing_year_range == null ? [] : response.data.sowing_year_range,
              showloader: false,
          });
      },
      (error) => {
          flag = true;
          this.setState({
              showloader: false,
              content:
                  (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                  error.message ||
                  error.toString(),
          });
          if (error.response) {
              TriggerAlert("Error", error.response.data.message, "error");
          } else {
              TriggerAlert(
                  "Error",
                  "Server closed unexpectedly, Please try again",
                  "error"
              );
          }
      },
      setTimeout(() => {
          if (flag == false) {
              this.setState({
                  showloader: false,
              });
              TriggerAlert("Error", "Response Timed out, Please try again", "info");
              this.navigateMainBoard()
          }
      }, 30000)
  );
   }

    // UserService.getCropsList(fpoId,'').then(
    //   (response) => {
    //     flag = true;
    //     // var dateDict = {};
    //     // response.data.sowing_year_range.map((date) => {
    //     //   dateDict[date] = date;
    //     // });
    //     this.setState({
    //       cropsdata: response.data.crops,
    //       // dateRanges: dateDict,
    //       showloader: false,
    //     });
    // //     setTimeout(() => {
    // //       this.setState({showloader: false })
    // //  }, 5000)
    //   },
    //   (error) => {
    //     flag = true;
    //     this.setState({
    //       showloader: false,
    //       content:
    //         (error.response &&
    //           error.response.data &&
    //           error.response.data.message) ||
    //         error.message ||
    //         error.toString(),
    //     });
    //     if (error.response) {
    //       TriggerAlert("Error", error.response.data.message, "error");
    //     } else {
    //       TriggerAlert(
    //         "Error",
    //         "Server closed unexpectedly, Please try again",
    //         "error"
    //       );
    //     }
    //   },
    //   // we r using setTimeOut function to display error message for a period of Time.
    //   setTimeout(() => {
    //     if (flag == false) {
    //       this.setState({
    //         showloader: false,
    //       });
    //       TriggerAlert("Error", "Response Timed out, Please try again", "info");
    //       // this.props.history.push("/dashboard");
    //       this.navigateMainBoard()
    //     }
    //   }, 30000)
    // );
  }

//   //appending year range in a dropdown
  YearOptions = (yearList) => {
    if(yearList.length!==null){
      return yearList.map((year, index) =>
    (
        <option key={index} name={year} value={year}>
            {year}
        </option>
    ))
    }
}
    // here we r rendering Material Table && have given all the title inside title: of Material Table 
  render() {
    const { cropsdata, dateRanges, showloader,currentFpo } = this.state;
    const columns = [
   
      {
        title: "Crop Name",
        field: "crop__crop_name",
        filtering: false,
        render: (rowData) => {
          let cropId = rowData.crop__id;
          let season = rowData.season;
          let Year = rowData.Year;
          let isVerified = rowData.is_verified;
          let cropName = rowData.crop__crop_name;
          let area = rowData.TotalArea;
          return (
            <div
              onClick={() =>
                this.navigateToPage(
                  season,
                  Year,
                  isVerified,
                  cropId,
                  area,
                  cropName,
                  "cropDetails"
                )
              }
            >
              <a href="#!">{cropName}</a>
            </div>
          );

        },
        cellStyle: {
          width: "15%",
        },
      },
      {
        title: "Status",
        field: "is_verified",
        lookup: { 1: "Unverified", 2: "Verified" },
        cellStyle: {
          width: "15%",
        },
      },
      {
        title: "Season",
        field: "season",
        lookup: { Zaid: "Zaid", Kharif: "Kharif", Rabi: "Rabi", Perennial:'Perennial' },
        // defaultFilter: [this.state.CurrentSeason],
        cellStyle: {
          width: "15%",
        },
      },
      {
        title: "Year",
        field: "Year",
        // lookup: dateRanges,
        // defaultFilter: [this.state.CurrentYear],
        cellStyle: {
          width: "15%",
        },
        // render:(rowData)=>{
        //   if(rowData.Year != undefined){
        //     return <p>{rowData['Year']}</p>
        //   }
        //   else{
        //     return <p>{this.state.currentYear}</p>
        //   }
        // }
      },
      {
        title: "Site Count",
        field: "sitecount",
        filtering: false,
        cellStyle: {
          width: "15%",
        },
      },
      {
        title: "Total Area (in Acres)",
        field: "TotalArea",
        filtering: false,
        cellStyle: {
          width: "15%",
        },
      },
      // {
      //   title: "Total Yield (in Qtl.)",
      //   field: "TotalYield",
      //   filtering: false,
      //   cellStyle: {
      //     width: "15%",
      //   },
      // },
      // {
      //   title: "Input Requirements",
      //   field: "",
      //   filtering: false,
      //   render: (rowData) => {
      //     let cropId = rowData.crop__id;
      //     let season = rowData.season;
      //     // let year = rowData.year;
      //     let year = rowData.date_range;

      //     let isVerified = rowData.is_verified;
      //     let area = rowData.TotalArea;
      //     return (
      //       <div
      //         onClick={() =>
      //           this.navigateToPage(season, year, isVerified, cropId, area)
      //         }
      //       >
      //         <a href="#!">VIEW</a>
      //       </div>
      //     );
      //   },
      //   cellStyle: {
      //     width: "15%",
      //   },
      // },
    ];
    // if cropsdata is there then it will display materialTable else will display NO Data 
    if (cropsdata) {
      return (
        <section className="mainWebContentSection">
          <Fragment>
            <div className="breadcrumb pageBreadCrumbHolder landHoldingBreadCrumbWrap">
              <a
                href="#"
                className="breadcrumb-item pageBreadCrumbItem"
                onClick={() => this.navigateMainBoard()}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
                  style={{ fontSize: "0.7rem" }}
                />
                &nbsp;Dashboard
              </a>
              {this.state.isParentLogged || this.state.isManagerLogged? <a
                href="#"
                className="breadcrumb-item pageBreadCrumbItem"
                onClick={() => this.navback()}
              >
                <FontAwesomeIcon
                  icon={faCaretLeft}
                  className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
                  style={{ fontSize: "0.7rem" }}
                />
                &nbsp; FPO Cropdata
              </a> :''

              }
            
            </div>
            {this.state.isParentLogged || this.state.isManagerLogged? 
            <div style={{ marginLeft: "30px", color: 'rgba(114, 49, 12, 1)'  }} >
              <h5 style={{marginLeft:"28px"}}> FPO: {currentFpo} </h5>
            </div>
          : ""}
          <div className="width-90">
                      <div style={{width:"80%",margin:"auto", marginTop:'10px'}}>
                        <Row >
                          <Col sm={1}></Col>
                          <Col sm={2}>
                              <label style={{color:"brown", fontSize:"15px"
                                }}>Select Status:</label>
                                <Form.Control
                                  size="sm"
                                  as="select"
                                //   className={this.state.ceomonthclass}
                                  value={this.state.selectStatus}
                                  onChange={(e)=>{
                                    this.setState({
                                      selectStatus: e.target.value
                                    })
                                }}
                                  style={{border:"1px solid grey",color:"brown"
                                }}

                                >
                                  {/* <option value="">{this.state.CurrentSeason}</option> */}
                                  <option value="-1">All</option>

                                  <option value="1">Unverified</option>
                                  <option value="2">Verified</option>
                                  
                                
                                </Form.Control>
                              </Col>
                            <Col sm={2}>
                              <label style={{color:"brown", fontSize:"15px"
                                }}>Select Season:</label>
                                <Form.Control
                                  size="sm"
                                  as="select"
                                //   className={this.state.ceomonthclass}
                                  value={this.state.selectSeason}
                                  onChange={(e)=>{
                                    this.setState({
                                      selectSeason: e.target.value
                                    })
                                }}
                                  style={{border:"1px solid grey",color:"brown"
                                }}

                                >
                                  {/* <option value="">{this.state.CurrentSeason}</option> */}
                                  <option value="Kharif">Kharif</option>

                                  <option value="Zaid">Zaid</option>
                                  <option value="Rabi">Rabi</option>
                                  <option value="Perennial">Perennial</option>
                                
                                </Form.Control>
                              </Col>
                              <Col sm={2}>
                              <label style={{color:"brown", fontSize:"15px"
                                }}>Select Start Year:</label>
                                <Form.Control
                                  size="sm"
                                  as="select"
                                //   className={this.state.ceomonthclass}
                                  value={this.state.startYear}
                                onChange={(e)=>{
                                  this.setState({
                                    startYear: e.target.value
                                  })
                              }}
                                  style={{border:"1px solid grey",color:"brown"}}

                                >
                                  {/* <option value="">{this.state.CurrentYear}</option> */}
                                  {this.YearOptions(dateRanges)}
                                        

                                
                                </Form.Control>
                              </Col>
                              <Col sm={2}>
                              <label style={{color:"brown", fontSize:"15px"
                                }}>Select End Year:</label>
                                <Form.Control
                                  size="sm"
                                  as="select"
                                //   className={this.state.ceomonthclass}
                                  value={this.state.endYear}
                                onChange={(e)=>{
                                  this.setState({
                                    endYear: e.target.value
                                  })
                              }}
                                  style={{border:"1px solid grey",color:"brown"}}

                                >
                                  {/* <option>{this.state.CurrentYear}</option> */}
                                  {this.YearOptions(dateRanges)}
                                        

                                
                                </Form.Control>
                              </Col>
                              <div style={{marginTop:'20px'}} >
                              <Button onClick={this.cropListFilter} >
                                Submit
                              </Button>
                              </div>
                              </Row>
                              
                            </div>
                          </div>

            <div className="landholdingHeader wrap">
              <Row>
                <Col lg="12" md="12" sm="12" className="noPadding">
                  <div className="PageHeading padding15">
                    <h4
                      className="farmerListHeading dvaraBrownText"
                      style={{ marginLeft: "40px",fontSize:"22px" }}
                    >
                      Crops List
                    </h4>
                  </div>
                  {showloader ? (
                    <div className="mainCropsFarmerLoaderWrap">
               {/* <img src="https://i.pinimg.com/originals/18/42/81/184281f0fe87517a950beb8112c308dd.gif" height="200px" style={{position:"relative",top:"100px",left:"40%"}}/> */}

                        <img src={crop_growing} height="200px" style={{position:"relative",top:"100px",left:"40%"}}/>
                      {/* <span className="spinner-border spinner-border-lg mainCropsFarmerLoader"></span> */}
                    </div>
                  ) : (
                    <MaterialTable
                      icons={tableIcons}
                      style={{ marginLeft: "50px" }}
                      title=""
                      data={cropsdata}                    
                      columns={columns}
                      actions={
                        [
                          // {
                          //   icon: VisibilityIcon,
                          //   tooltip: "View Crop Analytics",
                          //   onClick: (event, rowData) =>
                          //     this.props.history.push(
                          //       "/cropanalytics/" +
                          //         rowData.crop__id +
                          //         "/" +
                          //         rowData.crop__crop_name.toString() +
                          //         ""
                          //     ),
                          // },
                          /* {
                                      icon: tableIcons.Edit,
                                      tooltip: 'Edit',
                                      onClick: (event, rowData) => alert("Are sure you want to edit site: " + rowData.siteName)
                                    },
                                  {
                                    icon: tableIcons.Delete,
                                    tooltip: 'Delete',
                                    onClick: (event, rowData) => window.confirm("Are you sure you want to delete " + rowData.siteName),
                                  } */
                        ]
                      }
                      options={{
                        maxBodyHeight:600,
                        actionsColumnIndex: -1,
                        doubleHorizontalScroll: true,
                        pageSize: 10,
                        pageSizeOptions: [             
                          10,
                          20,
                          50,
                          100,
                          { value:cropsdata.length, label: "All" },
                        ],
                        /* headerStyle: {
                                      backgroundColor: '#A3C614',
                                      color: '#efefef',
                                      fontSize: '1.1rem',
                                      fontWeight: 'bold'
                                  },
                                  rowStyle: {
                                      backgroundColor: '#e5e5e5',
                                      borderBottom: '2px solid #fff',
                                      fontSize: '0.9rem'
                                  }, */
                        headerStyle: {
                          backgroundColor: "#A3C614",
                          color: "#fff",
                          fontSize: "1.2rem",
                          fontFamily: "barlow_reg",
                          fontWeight: "bold",
                        },
                        rowStyle: {
                          backgroundColor: "#f1f1f1",
                          borderBottom: "2px solid #e2e2e2",
                          fontSize: "0.9rem",
                        },
                        // filtering: true,
                      }}
                    />
                  )}
                </Col>
              </Row>
            </div>
            <div className="verticalSpacer20"></div>
          </Fragment>
        </section>
      );
    } else {
      return (
        <section className="mainWebContentSection"  >
          <Fragment>
            <div className="landholdingHeader wrap" style={{marginLeft:'650px', fontSize:'20px'}}><b>No Data Available</b></div>
          </Fragment>
        </section>
      );
    }
  }
}