import React, { Component, Fragment, useState } from "react";
import { Redirect, Route, Link } from 'react-router-dom';
import UserService from "../services/user.service";
import "../assets/css/crops.css";
import MaterialTable from "material-table";
import tableIcons from './icons';
import {TriggerAlert,} from './dryfunctions';
import {

    Row,
    Col,
    Form
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthService from "../services/auth.service";

import { faHtml5, faHome } from "@fortawesome/free-solid-svg-icons";
//have used class component . Initially we are defining all the state variables which are used inside the component.

export default class FpoInput extends Component {
    constructor(props) {
        super(props)
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSeasonChange = this.handleSeasonChange.bind(this);
        const months = [
          'January', 'February', 'March', 'April', 'May', 'June', 
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const date = new Date();
        const currentMonth = months[date.getMonth()]
        const currentYear = new Date().getFullYear()
        this.state = {
            fpoInputcounts: [],
            loading: false,
            dateRanges: [],
            getProjectList:[],
            SelDate: currentYear,
            SelSeason: "all",
            showloader:true,
            monthRanges:[],
            SelOrg:"all",
            SelOrgId:'',
            SelMonth:currentMonth,
            isManagerLogged:false,

        };
    }
    // handleDateChange = (e) => {
    //     this.setState(
    //       {
    //         SelDate: e.target.value,
    //         fpoInputcounts: []
    //       }
    //       );
    //       this.fetchCropsCount(e.target.value, this.state.SelSeason)
    // }
    // handleSeasonChange = (e) => {
    //     this.setState(
    //       {
    //         SelSeason: e.target.value,
    //         fpoInputcounts: []
    //       }
    //     )
    //     this.fetchCropsCount(this.state.SelDate, e.target.value)
    // }

    // fetchCropsCount = (range, season) => {
      


    // }
    // on clicking on FPO Name in pivot table this function is trigerred . Here we are storing the fpoName and fpoId in localStorage
     //then with that fpoName we are navigating to different component and changing the URL also.


     handleOrgChange = (e) => {
      const selectedOrgName = e.target.value;
      
      // Find the selected organization by name
      const selectedOrg = this.state.getProjectList.find(org => org.name === selectedOrgName);
      
      // Update state with selected organization details
      this.setState({
        SelOrg: selectedOrgName, // Store the selected name
        SelOrgId: selectedOrg ? selectedOrg.id : null, // Store the selected id
        fpoInputcounts: [],
        showloader: true,
      });
    
      // Call fetchCropsCount with the selected organization's id
      this.fetchBoCount(this.state.SelDate, this.state.SelMonth, this.state.SelSeason, selectedOrg ? selectedOrg.id : 'all');
    }

    handleDateChange = (e) => {
      this.setState(
        {
          SelDate: e.target.value,
          fpoInputcounts: [],
          showloader:true
        }
        );
        this.fetchBoCount(e.target.value, this.state.SelMonth ,this.state.SelSeason, this.state.SelOrgId?this.state.SelOrgId:'all')
  }

  handleSeasonChange = (e) => {
    console.log("this is being caleddddddddddddd 222", e.target.value)
    this.setState(
      {
        SelSeason: e.target.value,
        fpoInputcounts: [],
        showloader:true
      }
    )


    console.log("check vall season", this.state.SelSeason);
    this.fetchBoCount(this.state.SelDate,this.state.SelMonth, e.target.value, this.state.SelOrgId?this.state.SelOrgId:'all')
}

handleMonthChange = (e) => {
  console.log("this is being caleddddddddddddd", e.target.value)
  this.setState(

    {
      SelMonth : e.target.value,
      fpoInputcounts: [],
        showloader:true
        
      }
      
    )
    console.log("check vall", this.state.SelMonth);
this.fetchBoCount(this.state.SelDate, e.target.value, this.state.SelSeason, this.state.SelOrgId?this.state.SelOrgId:'all')



}



navigateToPage2= (pageName) => {
       
  this.props.history.push("/" + pageName + "");
};

navigateToPage= (pageName,fpoId,fpoName) => {
  localStorage.setItem("fpoId", JSON.stringify(fpoId));
  localStorage.setItem("fpoName", fpoName);

  this.props.history.push(
    "/business-opportunity/" +
      fpoName
  );
    // this.props.history.push("/" + pageName + "");
  };




fetchBoCount = (range, month,season, org) => {
  var flag = false;
  this.setState({
    showloader:true,
    fpoInputcounts:[]
  })
  UserService.getFpoBoCount(range, month, season, org).then(
    
      (response) => {
          flag = true; 
          
          if (response.data.success) {
              this.setState({
                  showloader:false,
                  fpoInputcounts : response.data.fpo_data,
                  // ranges: response.data.date_ranges_list

              });
          }
          else {
              this.setState({
                  showloader: false,
              });
              if (response){
                  TriggerAlert("Error",response.data.message,"error");
                }
                else {
                  TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
                }
          }
      },
      (error) => {
          flag = true; 
          this.setState({
              showloader: false,
              content:
                  (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                  error.message ||
                  error.toString(),
          });
          if (error.response){
              TriggerAlert("Error",error.response.data.message,"error");
            }
            else {
              TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
            }
      },
      setTimeout(() => {
          if(flag==false){
              this.setState({
                  showloader:false,
              });
          TriggerAlert("Error","Response Timed out, Please try again","info");
          this.props.history.push('/fpohomeData')             
        }
      }, 30000)
  );
}


             //this function will navigate to Organization Dahboard.

      
   //in componentDidMount initially we are checking if it is a valid user or not .Then we are checking if valid user is parent or not then accordinly we are 
    // navigating to the component. Then we are calling API.
    componentDidMount() {

      var flag = false;
      const fpoId = localStorage.getItem("fpoId")
     
      const user = AuthService.getCurrentUser();
      
      if(user.is_project_manager){
        this.setState({isManagerLogged:true})
      }

      if(!user){
        this.props.history.push('/')
        return
      }
      if(!user.is_parent && !user.is_project_manager)
        this.props.history.push("/dashboard")

      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear()

      this.fetchBoCount(currentYear, currentMonth, 'all', 'all')
    
      UserService.getProjectList().then(
        (response) => {
            flag = true;



            this.setState({
                  
              getProjectList: response.data.data == null ? [] : response.data.data,
              
                showloader: false,
            });
        },
        (error) => {
            flag = true;
            this.setState({
                showloader: false,
                content:
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString(),
            });
            if (error.response) {
                TriggerAlert("Error", error.response.data.message, "error");
            } else {
                TriggerAlert(
                    "Error",
                    "Server closed unexpectedly, Please try again",
                    "error"
                );
            }
        },
        setTimeout(() => {
            if (flag == false) {
                this.setState({
                    showloader: false,
                });
                TriggerAlert("Error", "Response Timed out, Please try again", "info");
                this.navigateMainBoard()
            }
        }, 30000)
    )

    if(user.is_parent){
      UserService.getYearRangesNoFpo('product_wise', fpoId).then(
        (response) => {
            flag = true;
            // var dateDict = {};
            // response.data.date_range.map((date) => {
            //     dateDict[date] = date;
            // });
            this.setState({
                
                dateRanges: response.data.sowing_year_range == null ? [] : response.data.sowing_year_range,
                monthRanges: response.data.months == null ? [] : response.data.months,
                showloader: false,
            });
        },
        (error) => {
            flag = true;
            this.setState({
                showloader: false,
                content:
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString(),
            });
            if (error.response) {
                TriggerAlert("Error", error.response.data.message, "error");
            } else {
                TriggerAlert(
                    "Error",
                    "Server closed unexpectedly, Please try again",
                    "error"
                );
            }
        },
        setTimeout(() => {
            if (flag == false) {
                this.setState({
                    showloader: false,
                });
                TriggerAlert("Error", "Response Timed out, Please try again", "info");
                this.navigateMainBoard()
            }
        }, 30000)
    )
    }else {
      UserService.getYearRangesNoFpoManager('product_wise', fpoId).then(
        (response) => {
            flag = true;
            // var dateDict = {};
            // response.data.date_range.map((date) => {
            //     dateDict[date] = date;
            // });
            this.setState({
                
                dateRanges: response.data.sowing_year_range == null ? [] : response.data.sowing_year_range,
                monthRanges: response.data.months == null ? [] : response.data.months,
                showloader: false,
            });
        },
        (error) => {
            flag = true;
            this.setState({
                showloader: false,
                content:
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString(),
            });
            if (error.response) {
                TriggerAlert("Error", error.response.data.message, "error");
            } else {
                TriggerAlert(
                    "Error",
                    "Server closed unexpectedly, Please try again",
                    "error"
                );
            }
        },
        setTimeout(() => {
            if (flag == false) {
                this.setState({
                    showloader: false,
                });
                TriggerAlert("Error", "Response Timed out, Please try again", "info");
                this.navigateMainBoard()
            }
        }, 30000)
    )
    }

   
}



createRangeOptions = (ranges) =>
        
  ranges.length
  ? ranges.map((data, i) => 
  (
      <option key={i} name={data} value={data}>
      {data}
      </option>
  ))
  : "";

  YearOptions = (yearList) => {
    if(yearList.length!==''){
      return yearList.map((year, index) =>
    (
        <option key={index} name={year} value={year}>
            {year}
        </option>
    ))
    }}




 

    render() {

        const { fpoInputcounts, SelDate, SelSeason, showloader,dateRanges, SelOrg , getProjectList, monthRanges, SelMonth,isManagerLogged } = this.state;
        console.log('getProjectList', SelMonth)
        const columns = [

            {
                title: "FPO Names",
                field: "name",
                filtering:false,
                render: (rowData) => {
                    let fpoId = rowData.id;
                    let fpoName=rowData.name;
                    return (
                      <div
                        onClick={() =>
                          this.navigateToPage("business-opportunity",fpoId,fpoName)
                           
                        }
                      >
                        <a href="#!">{rowData.name}</a>
                      </div>
                    );
          
                  },

                cellStyle: {
                  minWidth: 300,
                  maxWidth: 300
                },
              
            },
            {
                title: "Block",
                field: "block_name",
                filtering:false,

                cellStyle: {
                    width: "15%"
                }
            },
            {
                title: "District",
                field: "dist_name",
                filtering:false,

                cellStyle: {
                    width: "15%"
                },
            },
            {
                title: "State",
                field: "state_name",
                filtering:false,

                cellStyle: {
                    width: "15%"
                }
            },
            {
                title: "Total Number of Crops",
                field: "total_number_of_crops",
                filtering: false,

                cellStyle: {
                    width: "15%",
                },
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.total_number_of_crops == null ? 0 : rowData.total_number_of_crops;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
            },
            {
                title: "Estimated Tentative Price (In Rs.) (Output)",
                field: "total_tentative_price",
                filtering:false,
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.total_tentative_price == null || rowData.total_tentative_price== 0 ? 0 : rowData.total_tentative_price;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
                
                cellStyle: {
                    width: "15%"
                }
            },
            // {
            //     title: "Total Tentative Value (Rs)",
            //     field: "total_amount",
            //     filtering:false,
            //     render:(rowData)=>{
            //         return <NumberFormat value={rowData.total_amount} displayType={'text'}                   
            //         thousandSeparator={true} thousandsGroupStyle='lakh'/>
            //     },
            //     cellStyle: {
            //         width: "15%"
            //     }
            // },
         
           
          

        ];
        // const columns = [

        //     {
        //         title: "Fpo Name",
        //         field: "name",
        //         cellStyle:{
        //           minWidth: 350,
        //           maxWidth: 350
        //         },
        //         render: (rowData) => {
        //         //   let fpoId = rowData.id;
        //           let fpoName = rowData.name;
        //           return (
        //             <div
        //               onClick={() =>
        //                 this.navigateToPage("fpobo",fpoName)
                         
        //               }
        //             >
        //               <a href="#!">{rowData.name}</a>
        //             </div>
        //           );
        
        //         },
        //         // cellStyle: {
        //         //     width: "15%"
        //         // }
        //     },
        //     {
        //         title: "Block",
        //         field: "fpo_block",
        //         cellStyle: {
        //             width: "15%"
        //         }
        //     },
        //     {
        //         title: "District",
        //         field: "fpo_dist",
        //         cellStyle: {
        //             width: "15%"
        //         }
        //     },
        //     {
        //         title: "State",
        //         field: "fpo_state",
        //         cellStyle: {
        //             width: "15%"
        //         }
        //     },
        //     {
        //         title: "Total Inputs Listed(sales)",
        //         field: "input_product_count",
        //         filtering: false,

        //         cellStyle: {
        //             width: "15%"
        //         }
        //     },
        //     {
        //         title: "Rental Equipments Available",
        //         field: "farm_equipment_count",
        //         filtering:false,

        //         cellStyle: {
        //             width: "15%"
        //         }
        //     },
        //     // {
        //     //     title: "Season",
        //     //     // field: "crop_count",
        //     //     // filtering:false,
        //     //     lookup: { "Zaid": "Zaid", "Rabi": 'Rabi', "Kharif": 'Kharif' },
        //     //     cellStyle: {
        //     //         width: "15%"
        //     //     }
        //     // },

        // ];
        
            return (
              <section className="mainWebContentSection">
                <Fragment>
                <div  className="breadcrumb pageBreadCrumbHolder landHoldingBreadCrumbWrap"> 
                  <a
                href="#"
                className="breadcrumb-item pageBreadCrumbItem"
                onClick={() =>this.navigateToPage2("fpohomeData")
                }
              >
                <FontAwesomeIcon
                  icon={faHome}
                  className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
                  style={{ fontSize: "0.7rem" }}
                />
                &nbsp;Dashboard
              </a>
                  
                  
                  </div>
                  <div className="landholdingHeader wrap">
                    <Row>
                      <Col lg="12" md="12" sm="12" className="noPadding">
                      <div className="padding15 fpoCropsHeading">
                      <Row style={{ marginBottom: "20px", marginTop:"-20px" }}>
                      <Col md={6}>
      <h4 className="farmerListHeading dvaraBrownText" 
        style={{ marginLeft: "30px", 
          fontSize: "24px", 
          marginTop: "0",  
          marginBottom: "10px",
          whiteSpace: "nowrap" ,
          marginTop: "-10px",}} 
        >
          {this.state.isManagerLogged?"Business Opportunity Data":"Organization Business Opportunity Data"}
      
      </h4>
    </Col>
  </Row>
  <Row>
  <Col className="seasonDropdown" md={8}>
  <Form>
    <Row className="align-items-center" style={{marginTop:'-36px', marginLeft: '25px' }}>
      {isManagerLogged && (
        <Col xs={12} sm={3} className="mb-2"> {/* Adjusted to use xs=12 for full width in smaller screens */}
          <Form.Group>
            <Form.Label style={{
      fontSize: '15px', 
      color: "brown", 
      marginBottom: "5px", 
      whiteSpace: "nowrap",  // Prevent text wrapping
      overflow: "hidden",     // Handle overflow
      textOverflow: "ellipsis" // Optional: show ellipsis if text overflows
    }}>
              Select Organization
            </Form.Label>
            <Form.Control
              size="sm"
              as="select"
              value={SelOrg}
              onChange={this.handleOrgChange}
              style={{ border: "1px solid grey", color: "brown" }}
            >
              <option value="all">All</option>
              {getProjectList.map(org => (
                <option key={org.id} value={org.name}>{org.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      )}
      <Col xs={12} sm={3} className="mb-2"> {/* Adjusted to use xs=12 for full width in smaller screens */}
        <Form.Group>
          <Form.Label  
    style={{ 
      fontSize: '15px', 
      color: "brown", 
      marginBottom: "5px", 
      whiteSpace: "nowrap", 
      overflow: "hidden", 
      textOverflow: "ellipsis"
    }}>
            Select Month
          </Form.Label>
          <Form.Control
            size="sm"
            as="select"
            value={SelMonth}
            onChange={this.handleMonthChange}
            style={{ border: "1px solid grey", color: "brown" }}
          >
            {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map(month => (
              <option key={month} value={month}>{month}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col xs={12} sm={3} className="mb-2"> {/* Adjusted to use xs=12 for full width in smaller screens */}
        <Form.Group>
          <Form.Label style={{ fontSize: '15px', color: "brown" }}>
            Select Year
          </Form.Label>
          <Form.Control
            size="sm"
            as="select"
            value={SelDate}
            onChange={this.handleDateChange}
            style={{ border: "1px solid grey", color: "brown" }}
          >
            {this.YearOptions(dateRanges)}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col xs={12} sm={3} className="mb-2"> {/* Adjusted to use xs=12 for full width in smaller screens */}
        <Form.Group>
          <Form.Label style={{ fontSize: '15px', color: "brown" }}>
            Select Season
          </Form.Label>
          <Form.Control
            size="sm"
            as="select"
            value={SelSeason}
            onChange={this.handleSeasonChange}
            style={{ border: "1px solid grey", color: "brown" }}
          >
            <option value="all">All</option>
            <option value="Zaid">Zaid</option>
            <option value="Kharif">Kharif</option>
            <option value="Rabi">Rabi</option>
            <option value="Perennial">Perennial</option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Row>
  </Form>
</Col>

  </Row>
</div>

                        {showloader ? (
                          <div className="mainCropsFarmerLoaderWrap">
                            <span className="spinner-border spinner-border-lg mainCropsFarmerLoader"></span>
                          </div>
                        ) : (
                          <MaterialTable
                            icons={tableIcons}
                            title=""
                            style={{ marginLeft: "30px" , bottom:'30px',  marginTop: "20px"}}
                            data={fpoInputcounts}
                            columns={columns}
                            actions={
                              [
                                // {
                                //     icon: tableIcons.Edit,
                                //     tooltip: 'Edit',
                                //     onClick: (event, rowData) => showModal(true, rowData)
                                // },
                                // rowData => ({
                                //     icon: tableIcons.Delete,
                                //     tooltip: 'Delete',
                                //     isFreeAction: true,
                                //     onClick: (event, rowData) => {if(window.confirm('Are you sure to delete this "'+ rowData.wsp + '" record?'))
                                //                             {this.createWsp(rowData.id)}},
                                //   })
                              ]
                            }
                            options={{
                              maxBodyHeight:500,
                              actionsColumnIndex: -1,
                              doubleHorizontalScroll: true,
                              pageSize: 10,
                              pageSizeOptions: [
                                10,
                                20,
                                50,
                                100,
                                { value: fpoInputcounts.length, label: "All" },
                              ],
                              exportButton: true,

                              headerStyle: {
                                backgroundColor: "#A3C614",
                                color: "#fff",
                                fontSize: "1.2rem",
                                fontFamily: "barlow_reg",
                                fontWeight: "bold",
                              },
                              rowStyle: {
                                backgroundColor: "#f1f1f1",
                                borderBottom: "2px solid #e2e2e2",
                                fontSize: "0.9rem",
                              },
                              filtering: true,
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="verticalSpacer20"></div>
                </Fragment>
              </section>
            );
        
    }
}