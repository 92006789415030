import React, { Component, Fragment, useState } from "react";
import { Redirect, Route, Link } from 'react-router-dom';
import UserService from "../services/user.service";
import "../assets/css/crops.css";
import MaterialTable from "material-table";
import tableIcons from './icons';
import {TriggerAlert,} from './dryfunctions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthService from "../services/auth.service";

import { faHtml5, faHome } from "@fortawesome/free-solid-svg-icons";
import {

    Row,
    Col,
    Form
} from "react-bootstrap";
//have used class component . Initially we are defining all the state variables which are used inside the component.

export default class FpoProcurement extends Component {
    constructor(props) {
        super(props)
        //binding functions used with this.
        this.handleYearChange = this.handleYearChange.bind(this);
        this.handleMonthChange = this.handleMonthChange.bind(this);
        
        this.state = {
            fpoproccounts: [],
            // months : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            years: [],
            loading: false,
            SelMnt: "all",
            SelYear: "all",
            showloader:true,
            SelOrg:'all',
            getProjectList:[],
            isManagerLogged:false,
          

        };
    }

    handleOrgChange = (e) => {
      const selectedOrgName = e.target.value;
      
      // Find the selected organization by name
      const selectedOrg = this.state.getProjectList.find(org => org.name === selectedOrgName);
      
      // Update state with selected organization details
      this.setState({
        SelOrg: selectedOrgName, // Store the selected name
        SelOrgId: selectedOrg ? selectedOrg.id : null, // Store the selected id
        fpoFIcounts: [],
        showloader: true,
      });
    
      // Call fetchCropsCount with the selected organization's id
      this.fetchProcsCount(this.state.SelMnt, this.state.SelYear, selectedOrg ? selectedOrg.id : 'all');
    }
     //on selecting a data Under Month option this function is called . Here we are choosing the Month . and according to the value choosed we are making 
    // ApI call .This is a get API call.
    handleMonthChange = (e) => {
        this.setState(
          {
            SelMnt: e.target.value,
            fpoproccounts: [],
            showloader:true,
          }
          );
          this.fetchProcsCount(e.target.value, this.state.SelYear, this.state.SelOrgId?this.state.SelOrgId:'all')
          
    }
      //on selecting a data Under Year option this function is called . Here we are choosing the Year . and according to the value choosed we are making 
    // ApI call .This is a get API call.
    handleYearChange = (e) => {
        this.setState(
          {
            SelYear: e.target.value,
            fpoproccounts: [],
            showloader:true,
          }
        )

        this.fetchProcsCount(this.state.SelMnt, e.target.value, this.state.SelOrgId?this.state.SelOrgId:'all')
    }
       //here in dropdown under Month we  are appending all the Month.

    createMonthOptions = (months) =>
        months.map((data, i) => 
        (
            <option key={i} name={data} value={data}>
            {data}
            </option>
        ))
    //here in dropdown under Year we  are appending all the Year received after making an Api Call.

    createYearOptions = (years) =>
        years.map((data, i) => 
        (
            <option key={i} name={data} value={data}>
            {data}
            </option>
        ))
      // on clicking on FPO Name in pivot table this function is trigerred . Here we are storing the fpoName and fpoId in localStorage
     //then with that fpoName we are navigating to different component and changing the URL also.
        navigateToPage= (pageName,fpoId,fpoName) => {
          localStorage.setItem("fpoId", JSON.stringify(fpoId));
          localStorage.setItem("fpoName", JSON.stringify(fpoName));

          // ))
          this.props.history.push(
            "/commodities/" +
          
              fpoName
            
          );
                   };
          //this function will navigate to Organization Dahboard.
          navigateToPage2= (pageName) => {
       
            this.props.history.push("/" + pageName + "");
          };
    
    //In fetchProcsCount function we are making a API call . Here filters are handled by backend only . Initailly on loading the page by default we are choosing
  // all as a option in month and year . Later on changing it we are making a API call and displaying error also in frontend.
    fetchProcsCount = (month, year, org) => {
      
        var flag = false;
        UserService.getFpoProcsCount(month, year, org).then(
            (response) => {
                flag = true; 
                if (response.data.success) {
                    this.setState({
                        showloader:false,
                        fpoproccounts: response.data.fpo_procs_data,
                        // years: response.data.years_list
                        
                    });
                }
                else {
                    this.setState({showloader:false,})
                    if (response){
                        TriggerAlert("Error",response.data.message,"error");
                      }
                      else {
                        TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
                      }
                }
            },
            (error) => {
                flag = true; 
                this.setState({
                    showloader:false,
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString(),
                });
                if (error.response){
                    TriggerAlert("Error",error.response.data.message,"error");
                  }
                  else {
                    TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
                  }
            },
            setTimeout(() => {
                if(flag==false){
                    this.setState({
                        showloader:false,
                    });
                TriggerAlert("Error","Response Timed out, Please try again","info");
                this.props.history.push('/fpohomeData')             

              }
            }, 30000)
        );
    }
    
     //in componentDidMount initially we are checking if it is a valid user or not .Then we are checking if valid user is parent or not then accordinly we are 
    // navigating to the component. Then we are calling fetchProcsCount function.
    componentDidMount() {
      // if(!localStorage.getItem('user')){
      //   this.props.history.push('/')
      //   return
      // }
      const user = AuthService.getCurrentUser();

      if(user.is_project_manager){
        this.setState({isManagerLogged:true})
      }

      if(!user){
        this.props.history.push('/')
        return
      }
      if(!user.is_parent && !user.is_project_manager)
        this.props.history.push("/dashboard")
      
        this.fetchProcsCount('all', 'all', 'all')

        var flag=false;

        if(user.is_parent){
          UserService.getYearRangesNoFpo('commodity').then(
            (response) => {
                flag = true;
                // var dateDict = {};
                // response.data.date_range.map((date) => {
                //     dateDict[date] = date;
                // });
                this.setState({
                    
                    years: response.data.sowing_year_range == null ? [] : response.data.sowing_year_range,
                    // monthRanges: response.data.months == null ? [] : response.data.months,
                    showloader: false,
                });
            },
            (error) => {
                flag = true;
                this.setState({
                    showloader: false,
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString(),
                });
                if (error.response) {
                    TriggerAlert("Error", error.response.data.message, "error");
                } else {
                    TriggerAlert(
                        "Error",
                        "Server closed unexpectedly, Please try again",
                        "error"
                    );
                }
            },
            setTimeout(() => {
                if (flag == false) {
                    this.setState({
                        showloader: false,
                    });
                    TriggerAlert("Error", "Response Timed out, Please try again", "info");
                    this.navigateMainBoard()
                }
            }, 30000)
        )
        }else{
          UserService.getYearRangesNoFpoManager('commodity').then(
            (response) => {
                flag = true;
                // var dateDict = {};
                // response.data.date_range.map((date) => {
                //     dateDict[date] = date;
                // });
                this.setState({
                    
                    years: response.data.sowing_year_range == null ? [] : response.data.sowing_year_range,
                    // monthRanges: response.data.months == null ? [] : response.data.months,
                    showloader: false,
                });
            },
            (error) => {
                flag = true;
                this.setState({
                    showloader: false,
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString(),
                });
                if (error.response) {
                    TriggerAlert("Error", error.response.data.message, "error");
                } else {
                    TriggerAlert(
                        "Error",
                        "Server closed unexpectedly, Please try again",
                        "error"
                    );
                }
            },
            setTimeout(() => {
                if (flag == false) {
                    this.setState({
                        showloader: false,
                    });
                    TriggerAlert("Error", "Response Timed out, Please try again", "info");
                    this.navigateMainBoard()
                }
            }, 30000)
        )
        }

        UserService.getProjectList().then(
          (response) => {
              flag = true;
              // var dateDict = {};
              // response.data.date_range.map((date) => {
              //     dateDict[date] = date;
              // });
              
              this.setState({
                  
                getProjectList: response.data.data== null ? [] : response.data.data,
                
                  showloader: false,
              });
          },
          (error) => {
              flag = true;
              this.setState({
                  showloader: false,
                  content:
                      (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                      error.message ||
                      error.toString(),
              });
              if (error.response) {
                  TriggerAlert("Error", error.response.data.message, "error");
              } else {
                  TriggerAlert(
                      "Error",
                      "Server closed unexpectedly, Please try again",
                      "error"
                  );
              }
          },
          setTimeout(() => {
              if (flag == false) {
                  this.setState({
                      showloader: false,
                  });
                  TriggerAlert("Error", "Response Timed out, Please try again", "info");
                  this.navigateMainBoard()
              }
          }, 30000)
      )
    }
  
    render() {

        const { fpoproccounts, SelMnt, SelYear, months, years, showloader, SelOrg, getProjectList } = this.state;
        const columns = [

            {
                title: "FPO Name",
                field: "name",
                cellStyle: {
                  minWidth: 350,
                  maxWidth: 350
                },
                render: (rowData) => {
                  let fpoId = rowData.id;
                  let fpoName = rowData.name;
                  return (
                    <div
                      onClick={() =>
                        this.navigateToPage("commodities",fpoId,fpoName)
                         
                      }
                    >
                      <a href="#!">{rowData.name}</a>
                    </div>
                  );
        
                },
                // cellStyle: {
                //     width: "15%"
                // }
            },
            {
                title: "Block",
                field: "fpo_block",
                cellStyle: {
                    width: "15%"
                }
            },
            {
                title: "District",
                field: "fpo_dist",
                cellStyle: {
                    width: "15%"
                }
            },
            {
                title: "State",
                field: "fpo_state",
                cellStyle: {
                    width: "15%"
                }
            },
            {
                title: "Commodities",
                field: "commodity_listed",
                filtering: false,

                cellStyle: {
                  maxWidth:150,
                  minWidth:150
              },
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.commodity_listed == null ? 0 : rowData.commodity_listed;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
            },
            {
                title: "Farmers Interested",
                field: "interested_farmers",
                filtering:false,
                cellStyle: {
                  maxWidth:150,
                  minWidth:150
              },
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.interested_farmers == null ? 0 : rowData.interested_farmers;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
            },
            {
                title: "Total Orders",
                field: "total_orders",
                filtering:false,
                cellStyle: {
                  maxWidth:150,
                  minWidth:150
              },
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.total_orders == null ? 0 : rowData.total_orders;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
            },
            {
                title: "Offered Qty",
                field: "total_qty",
                filtering:false,
                cellStyle: {
                  maxWidth:150,
                  minWidth:150
              },
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.total_qty == null ? 0 : rowData.total_qty;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
            },
            {
                title: "Total Value (In Rs)",
                field: "total_value",
                filtering:false,
                cellStyle: {
                    maxWidth:150,
                    minWidth:150
                },
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.total_value == null ? 0 : rowData.total_value;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
            }

        ];
        
            return (
              <section className="mainWebContentSection">
                <Fragment>
                <div  className="breadcrumb pageBreadCrumbHolder landHoldingBreadCrumbWrap"> 
                  <a
                href="#"
                className="breadcrumb-item pageBreadCrumbItem"
                onClick={() =>this.navigateToPage2("fpohomeData")}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
                  style={{ fontSize: "0.7rem" }}
                />
                &nbsp;Dashboard
              </a>
                  
                  
                  </div>
                  <div className="landholdingHeader wrap">
                    <Row>
                      <Col lg="12" md="12" sm="12" className="noPadding">
                      <div className="padding15 fpoCropsHeading">
                      <Row style={{ marginBottom: "20px", marginTop:"-20px" }}>
                      <Col md={6}>
      <h4 className="farmerListHeading dvaraBrownText" 
        style={{ marginLeft: "30px", fontSize: "24px" , marginTop:"-80%",marginBottom:"10px"}}
        >
           {this.state.isManagerLogged?"Procurements Data" :"Organization Procurements Data"}
        
      </h4>
    </Col>
  </Row>
  <Row>
    <Col className="seasonDropdown" md={8}>
      <Form>
        <Row style={{ marginTop: '-36px', marginLeft: '25px' }} className="align-items-center">
          {this.state.isManagerLogged && (
            <Col xs={12} sm={4} className="mb-2">
              <Form.Group>
                <Form.Label style={{ fontSize: '15px', color: "brown" }}>
                  Select Organization
                </Form.Label>
                <Form.Control
                  size="sm"
                  as="select"
                  value={SelOrg}
                  onChange={this.handleOrgChange}
                  style={{ border: "1px solid grey", color: "brown" }}
                >
                  <option value="all">All</option>
                  {getProjectList.map(org => (
                    <option key={org.id} value={org.name}>{org.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          )}
          <Col xs={12} sm={4} className="mb-2">
            <Form.Group>
              <Form.Label style={{ fontSize: '15px', color: "brown" }}>
                Select Month
              </Form.Label>
              <Form.Control
                size="sm"
                as="select"
                value={SelMnt}
                onChange={this.handleMonthChange}
                style={{ border: "1px solid grey", color: "brown" }}
              >
                <option value="all">All</option>
                <option value="01">January</option>
                <option value="02">February</option>
                <option value="03">March</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs={12} sm={4} className="mb-2">
            <Form.Group>
              <Form.Label style={{ fontSize: '15px', color: "brown" }}>
                Select Year
              </Form.Label>
              <Form.Control
                size="sm"
                as="select"
                value={SelYear}
                onChange={this.handleYearChange}
                style={{ border: "1px solid grey", color: "brown" }}
              >
                <option value="all">All</option>
                {this.createYearOptions(years)}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Col>
  </Row>
</div>

                        {showloader ? (
                          <div className="mainCropsFarmerLoaderWrap">
                            <span className="spinner-border spinner-border-lg mainCropsFarmerLoader"></span>
                          </div>
                        ) : (
                          <MaterialTable
                            icons={tableIcons}
                            title=""
                            style={{ marginLeft: "30px" , bottom:'35px', marginTop:"10px"}}
                            data={fpoproccounts}
                            columns={columns}
                            actions={
                              [
                                // {
                                //     icon: tableIcons.Edit,
                                //     tooltip: 'Edit',
                                //     onClick: (event, rowData) => showModal(true, rowData)
                                // },
                                // rowData => ({
                                //     icon: tableIcons.Delete,
                                //     tooltip: 'Delete',
                                //     isFreeAction: true,
                                //     onClick: (event, rowData) => {if(window.confirm('Are you sure to delete this "'+ rowData.wsp + '" record?'))
                                //                             {this.createWsp(rowData.id)}},
                                //   })
                              ]
                            }
                            options={{
                              maxBodyHeight:500,
                              actionsColumnIndex: -1,
                              doubleHorizontalScroll: true,
                              pageSize: 10,
                              pageSizeOptions: [
                                10,
                                20,
                                50,
                                100,
                                { value: fpoproccounts.length, label: "All" },
                              ],
                              exportButton: true,

                              headerStyle: {
                                backgroundColor: "#A3C614",
                                color: "#fff",
                                fontSize: "1.2rem",
                                fontFamily: "barlow_reg",
                                fontWeight: "bold",
                              },
                              rowStyle: {
                                backgroundColor: "#f1f1f1",
                                borderBottom: "2px solid #e2e2e2",
                                fontSize: "0.9rem",
                              },
                              filtering: true,
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="verticalSpacer20"></div>
                </Fragment>
              </section>
            );
        
    }
}